import {
    AdminWebhookFormData,
    AdminWebhookQueryParams,
    ResponseAdminWebhook,
    ResponseAdminWebhookEventsList,
    ResponseAdminWebhookList
} from "./models";
import Bubble from "../../utils/Bubble";
import __t from "../../utils/HandlebarsHelpers/__t";
import {error} from "jquery";

export default class AdminWebhookService {
    pathAdminWebhooksApiUrl: string
    pathAdminWebhookEventsApiUrl: string

    constructor() {
        this.pathAdminWebhooksApiUrl = "/admin/webhooks"
        this.pathAdminWebhookEventsApiUrl = "/admin/webhooks/events"
    }

    public async getAdminWebhookList(searchParams: AdminWebhookQueryParams): Promise<ResponseAdminWebhookList>
    {
        try {
            const queryString = new URLSearchParams(searchParams as any).toString()
            const response = await fetch( this.pathAdminWebhooksApiUrl + '?' + queryString, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: "GET"
            })
            if (response.ok) {
                const body = await response.json()
                return {
                    data: body.data,
                    pagination: {
                        current_page: body.pagination.current_page,
                        from: body.pagination.from,
                        to: body.pagination.to,
                        total: body.pagination.total,
                        per_page: body.pagination.per_page,
                        last_page: body.pagination.last_page,
                        previous_page: body.pagination.current_page-1,
                        next_page: body.pagination.current_page+1,
                        url: 'admin-webhooks',
                        nb_columns: 6
                    }
                }
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    public async getAdminWebhook(id: string): Promise<ResponseAdminWebhook>
    {
        try {
            const response = await fetch( this.pathAdminWebhooksApiUrl + '/' + id, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: "GET"
            })
            if (response.ok) {
                const body = await response.json()
                return {
                    data: body.data,
                }
            }
        } catch (error) {
            Bubble.error(__t('admin-webhooks.form.errors.no_webhook'))
            throw new Error(error)
        }
    }

    public async createAdminWebhook(data: AdminWebhookFormData){
            const response = await fetch(this.pathAdminWebhooksApiUrl, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: "POST",
                body: JSON.stringify(data),
            })
            if (response.ok) {
                window.location.href = `/admin/admin-webhooks?message=success`
            } else {
                const body = await response.json()
                if (body.errors == null) {
                    Bubble.error(__t('admin-webhooks.form.errors.select_event_name'))
                    throw new Error(body.message)
                }

                Object.keys(body.errors).forEach((input) => {
                    const errorMessage = body.errors[input] as string
                    const errorType = input === 'target' ? 'target' : input === 'comment' ? 'comment' : 'webhook_secret'
                    Bubble.error(__t(`admin-webhooks.form.errors.${errorType}_invalidation`) + errorMessage)
                })
                throw new Error(body.message)
            }
        }

    public async updateAdminWebhook(id: string, data: AdminWebhookFormData){
        const response = await fetch(this.pathAdminWebhooksApiUrl + '/' + id, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            method: "PATCH",
            body: JSON.stringify(data),
        })
        if (response.ok) {
            window.location.href = `/admin/admin-webhooks?message=success-edit`
        } else {
            const body = await response.json()
            Bubble.error(body.message)
            throw new Error(body.message)
        }
    }

    public async getAdminWebhookEvents(): Promise<ResponseAdminWebhookEventsList>
    {
        try {
            const response = await fetch( this.pathAdminWebhookEventsApiUrl, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: "GET"
            })
            if (response.ok) {
                const body = await response.json()
                return {
                    data: body.data,
                }
            }
        } catch (error) {
                Bubble.error(__t('admin-webhooks.form.errors.no_events'))
                throw new Error(error)
        }
    }
}
