import {Controller} from "../../../Router";
import AdminWebhookService from "../service";
import Bubble from "../../../utils/Bubble";
import __t from "../../../utils/HandlebarsHelpers/__t";
import {AdminWebhookFormData} from "../models";

const mainTemplate = require("./main.hbs")
const createAdminWebhookFormTemplate = require('./webhook_create.hbs')

export default class AdminWebhooksCreateManager implements Controller {
    container: HTMLElement
    vars: any
    service: AdminWebhookService

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.service = new AdminWebhookService()
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate()
        this.loadCreateAdminWebhookTemplate()
    }

    loadCreateAdminWebhookTemplate() {
        this.container.classList.add('loading-state')
        this.service.getAdminWebhookEvents()
            .then((content) => {
                this.vars['adminWebhookEvents'] = content.data
                const formTemplate = document.getElementById('content-add-admin-webhook')
                formTemplate.innerHTML = createAdminWebhookFormTemplate(this.vars)
                this.formListeners(formTemplate)
                this.container.classList.remove('loading-state')
            })
            .catch((err: Error) => {
                this.container.classList.remove('loading-state')
                Bubble.error(__t('admin-webhooks.list.messages.no_results_error'))
            })
    }

    private formListeners(formTemplate: HTMLElement): void
    {
        const form = formTemplate.querySelector<HTMLFormElement>('.create-admin-webhook-form')
        form.addEventListener('submit',async (event) => {
            event.preventDefault();
            const formData = new FormData(form)
            const event_name= formData.get('eventName') as string
            const target= formData.get('target') as string
            const comment= formData.get('comment') as string | null
            const webhook_secret= formData.get('webhookSecret') as string | null

            const submissionData: AdminWebhookFormData = {
                event_name,
                target,
                ...(comment ? { comment } : {}),
                ...(webhook_secret ? { webhook_secret } : {})
            }

            await this.service.createAdminWebhook(submissionData)
        })
    }
}
