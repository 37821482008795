var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.update.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":41}}}))
    + "</h1>\n<p>"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.update.description",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":46}}}))
    + "</p>\n<hr>\n\n    <div id=\"content-update-admin-webhook\">\n    </div>\n";
},"useData":true});