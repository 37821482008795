var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <option>"
    + container.escapeExpression(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"admin-webhooks.form.errors.no_events",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":70}}}))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"adminWebhookEvents") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":11,"column":21}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + " "
    + ((stack1 = __default(require("../../../utils/HandlebarsHelpers/ifeq.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"currentWebhook") : depths[1])) != null ? lookupProperty(stack1,"event_name") : stack1),{"name":"ifeq","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":101}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " - ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + ")</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form id=\"update-admin-webhook-form\" class=\"update-admin-webhook-form\">\n        <div class=\"form-group\">\n            <label for=\"eventNameDropdown\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.event_name.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":96}}}))
    + "<small><span style=\"color: red\"> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.required",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":129},"end":{"line":4,"column":167}}}))
    + "</span></small></label>\n            <select class=\"form-control\" id=\"eventNameDropdown\" name=\"eventName\" required autofocus>\n"
    + ((stack1 = __default(require("../../../utils/HandlebarsHelpers/ifeq.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"adminWebhookEvents") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),0,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":12,"column":21}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"form-group\">\n            <label for=\"inputTarget\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.target.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":16,"column":37},"end":{"line":16,"column":86}}}))
    + "<small><span style=\"color: red\"> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.required",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":16,"column":119},"end":{"line":16,"column":157}}}))
    + "</span></small></label>\n            <input type=\"url\" class=\"form-control\" id=\"inputTarget\" name=\"target\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentWebhook") : depth0)) != null ? lookupProperty(stack1,"target") : stack1), depth0))
    + "\" required autofocus>\n                <small class=\"text-muted\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.target.rules",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":42},"end":{"line":18,"column":91}}}))
    + "</small>\n        </div>\n        <div class=\"form-group\">\n            <label for=\"inputComment\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.comment.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":21,"column":38},"end":{"line":21,"column":88}}}))
    + "<small><em><span> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.optional",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":21,"column":106},"end":{"line":21,"column":144}}}))
    + "</span></em></small></label>\n            <input type=\"text\" class=\"form-control\" id=\"inputComment\" name=\"comment\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentWebhook") : depth0)) != null ? lookupProperty(stack1,"comment") : stack1), depth0))
    + "\" autofocus>\n        </div>\n        <div class=\"form-group\">\n            <label for=\"inputWebhookSecret\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.webhook_secret.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":25,"column":44},"end":{"line":25,"column":101}}}))
    + "<small><em><span> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.required",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":25,"column":119},"end":{"line":25,"column":157}}}))
    + "</span></em></small></label>\n            <input type=\"text\" class=\"form-control\" id=\"inputWebhookSecret\" name=\"webhookSecret\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentWebhook") : depth0)) != null ? lookupProperty(stack1,"webhook_secret") : stack1), depth0))
    + "\" minLength=\"32\" maxLength=\"64\">\n                <small class=\"text-muted\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.webhook_secret.rules",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":27,"column":42},"end":{"line":27,"column":99}}}))
    + "</small>\n        </div>\n        <div>\n            <a class=\"btn btn-secondary\" title="
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.update.cancel_button_title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":30,"column":47},"end":{"line":30,"column":98}}}))
    + " href=\"/admin/admin-webhooks\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":30,"column":128},"end":{"line":30,"column":152}}}))
    + "</a>\n            <button id=\"submit-update-admin-webhook-form\" type=\"submit\" class=\"btn btn-primary\" title=\""
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.update.submit_button_title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":31,"column":103},"end":{"line":31,"column":154}}}))
    + "\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.save",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":31,"column":156},"end":{"line":31,"column":178}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true,"useDepths":true});