var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<button class=\"btn btn-sm btn-primary\" data-toggle=\"modal\" data-target=\"#cm-create-tag\"><span class=\"fa fa-plus\"></span> "
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.buttons.add_category",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":1,"column":121},"end":{"line":1,"column":159}}}))
    + "</button>\n<div class=\"row\">\n    <div class=\"col-sm-3\">\n        <ul class=\"list-group mt-2\" id=\"catalog-content-tags\"></ul>\n    </div>\n    <div class=\"col-sm-9\" id=\"catalog-content-items\">\n    </div>\n</div>\n"
    + ((stack1 = container.invokePartial(require("./create-tag.hbs"),depth0,{"name":"create-tag","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./reorder-tags.hbs"),depth0,{"name":"reorder-tags.hbs","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});