import i18next from "i18next";
import { JobRequest } from "../jobs/models";
import JobsService from "../jobs/service";
import {NetworkApiError} from "../utils/Api";

let template = require("./export-modal.hbs")

export default class ExportManager {
    jobService = new JobsService()
    modal: JQuery<HTMLElement>
    timer: number|null
    shouldDismiss = false
    nbErrors = 0
    constructor() {
        this.modal = $(template()).modal()
        this.modal.on('hidden.bs.modal', () => {
            this.modal.remove()
            this.clearTimeout()
        })
    }

    triggerExport(url: string) {
        this.jobService.createJob(url)
            .then((job: JobRequest) => this.setJob(job))
            .catch((err: Error) => {
                this.showJobError(err)
            })
    }

    setJob(job: JobRequest) {
        this.nbErrors = 0
        if (job.status === "failed") {
            this.showJobError()
        } else if (job.status === "finished") {
            this.modal.find('.modal-body').hide()
            this.modal.find('.export-finished')
                .show()
                .find('.export-message')
                .html(i18next.t("export.success", { url: job.result.url }))
        } else if (!this.shouldDismiss) {
            this.timer = setTimeout(() => {
                this.updateJob(job)
            }, 5000)
        }
    }

    private showJobError(err: Error|undefined = undefined) {
        this.modal.find('.modal-body').hide()
        this.modal.find('.export-error-info').hide()
        this.modal.find('.export-error').show()
        if (err) {
            this.modal.find('.export-error-info').text(err.message).show()
        }
    }

    private updateJob(job: JobRequest) {
        this.jobService.getJobStatus(job.id)
            .then((job: JobRequest) => this.setJob(job))
            .catch((err: Error) => {
                if (this.shouldDismiss) {
                    return
                }
                if (this.isRecoverable(err) || ++this.nbErrors > 5) {
                    this.timer = setTimeout(() => {
                        this.updateJob(job)
                    }, 15000)
                } else {
                    this.showJobError(err)
                }
            })
    }

    private isRecoverable(err: Error): boolean {
        return err instanceof NetworkApiError
    }

    private clearTimeout() {
        if (this.timer != null) {
            clearTimeout(this.timer)
            this.timer = null
        }
        this.shouldDismiss = true
    }

    dismiss() {
        this.clearTimeout()
        this.modal.modal('hide')
    }
}