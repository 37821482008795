/** global: ajax_url */

function h_ajax_filter_success(fn, data, textStatus, jqXHR){
	if (data.status == 301) {
		if (data.url) {
			window.location = data.url
		} else {
			window.location.reload()
		}
		return
	}
	if (fn) {
		fn(data, textStatus, jqXHR);
	}
}

function h_ajax_filter_error(fn, jqXHR, textStatus, errorThrown){
	var data = null;
	try {
		data = JSON.parse(jqXHR.responseText);
		if (data.status == 301) {
			if (data.url) {
				window.location = data.url
			} else {
				window.location.reload()
			}
			return
		}
		if (data.error) {
			h_popin_text(data.error);
		} else if (data.message) {
			h_popin_text(data.message);
		} else {
			h_popin_text('Une erreur est survenue durant la requête...');
		}
	} catch (e){
		h_popin_text('Une erreur est survenue durant la requête...');
	}
	if (fn) {
		fn(data, jqXHR, textStatus, errorThrown);
	}
}

window.h_ajax = function(action, jquery_args){
	return window.hAjax(ajax_url+action, jquery_args);	
}


window.hAjax = function(url, jquery_args){
	var s = null, e = null;
	if (jquery_args.dataType) {
		jquery_args.dataType = 'json'
	}
	if (jquery_args.dataType == 'json') {
		if (jquery_args.success) {
			s = jquery_args.success;
		}
		if (jquery_args.error) {
			e = jquery_args.error;
		}
		jquery_args.success = function(data, textStatus, jqXHR){
			h_ajax_filter_success(s, data, textStatus, jqXHR);
		};
		if (!jquery_args.noerrorfilter) {
			jquery_args.error = function (jqXHR, textStatus, errorThrown) {
				h_ajax_filter_error(e, jqXHR, textStatus, errorThrown);
			};
		}
	}
	return jQuery.ajax(url, jquery_args);
}
