var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <a class=\"btn btn-xs btn-outline-secondary\" href=\"/catalogs\">\n        <span class=\"fa fa-chevron-left\"></span> <span class=\"d-none d-sm-inline\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.back",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":3,"column":82},"end":{"line":3,"column":104}}}))
    + "</span>\n    </a>\n    <div class=\"btn-group btn-group-xs float-right\">\n        <a class=\"btn btn-outline-secondary\" href=\"/catalogs/"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"catalog") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/edit\" title=\""
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.buttons.edit",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":6,"column":89},"end":{"line":6,"column":119}}}))
    + "\" data-toggle=\"tooltip\">\n            <span class=\"fa fa-edit\"></span>\n        </a>\n        <button class=\"btn btn-outline-secondary action_push\">\n            <span class=\"fa fa-upload\"></span> "
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.buttons.push",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":77}}}))
    + "\n        </button>\n    </div>\n</div>\n<h1>"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"catalog") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n<p>"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.description",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":15,"column":3},"end":{"line":15,"column":32}}}))
    + "</p>\n<hr>\n\n<div class=\"container-fluid\">\n    <ul class=\"nav nav-tabs mb-2 catalog_manager_nav\">\n        <li class=\"nav-item\">\n            <a class=\"nav-link active\" href=\"javascript:void 0\" data-menu=\"items\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.nav.items",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":21,"column":82},"end":{"line":21,"column":109}}}))
    + "</a>\n        </li>\n        <li class=\"nav-item\">\n            <a class=\"nav-link\" href=\"javascript:void 0\" data-menu=\"options\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.nav.options",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":24,"column":77},"end":{"line":24,"column":106}}}))
    + "</a>\n        </li>\n        <li class=\"nav-item\">\n            <a class=\"nav-link\" href=\"javascript:void 0\" data-menu=\"menus\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.nav.menus",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":27,"column":75},"end":{"line":27,"column":102}}}))
    + "</a>\n        </li>\n    </ul>\n    <div class=\"catalog_manager_content\"></div>\n</div>\n<div class=\"modal modal_content\" id=\"pusherModal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"pusherModal\" aria-hidden=\"true\"></div>";
},"useData":true});