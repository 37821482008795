var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal\" id=\"cm-reorder-tags\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.reorder-tags.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":76}}}))
    + "</h5>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <div class=\"modal-body\">\n            </div>\n            <div class=\"modal-footer\">\n                <button class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":13,"column":71},"end":{"line":13,"column":95}}}))
    + "</button>\n                <button class=\"btn btn-primary save\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.save",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":14,"column":53},"end":{"line":14,"column":75}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});