
export default class ImportMenuModule {
    importUrls: {[key: string]: string}

    constructor() {
        this.importUrls = {
            'options': '/pim/options/import',
            'dishes': '/pim/dishes/import',
            'menu': '/pim/menu/import'
        }
    }

    public importFile(type: string, form: FormData)
    {
        let url = this.importUrls[type];
        return fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
            method: "POST",
            redirect: "follow",
            body: form
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
                return body
            })
    }

}