var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal\" id=\"ol-create-limit\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":81}}}))
    + "</h5>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <form id=\"ol-create-limit-form\">\n                <div class=\"modal-body\">\n                    <p class=\"alert alert-danger ol_error\" style=\"display: none\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.errors.unknown",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":12,"column":81},"end":{"line":12,"column":131}}}))
    + "</p>\n                    <div class=\"form-group\">\n                        <label for=\"inputOLDayOfWeek\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.day_of_week.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":14,"column":54},"end":{"line":14,"column":107}}}))
    + "</label>\n                        <select class=\"form-control\" id=\"inputOLDayOfWeek\" name=\"day_of_week\" required>\n                            <option value=\"1\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.monday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":16,"column":46},"end":{"line":16,"column":76}}}))
    + "</option>\n                            <option value=\"2\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.tuesday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":77}}}))
    + "</option>\n                            <option value=\"3\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.wednesday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":46},"end":{"line":18,"column":79}}}))
    + "</option>\n                            <option value=\"4\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.thursday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":19,"column":46},"end":{"line":19,"column":78}}}))
    + "</option>\n                            <option value=\"5\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.friday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":20,"column":46},"end":{"line":20,"column":76}}}))
    + "</option>\n                            <option value=\"6\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.saturday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":21,"column":46},"end":{"line":21,"column":78}}}))
    + "</option>\n                            <option value=\"7\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.sunday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":22,"column":46},"end":{"line":22,"column":76}}}))
    + "</option>\n                        </select>\n                        <small class=\"invalid-feedback\"></small>\n                    </div>\n                    <div class=\"row\">\n                        <div class=\"col-6\">\n                            <div class=\"form-group\">\n                                <label for=\"inputOLStartTime\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.start_time.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":29,"column":62},"end":{"line":29,"column":114}}}))
    + "</label>\n                                <input type=\"time\" class=\"form-control\" id=\"inputOLStartTime\" name=\"start_time\" placeholder=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.start_time.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":30,"column":125},"end":{"line":30,"column":183}}}))
    + "\" required>\n                                <small class=\"invalid-feedback\"></small>\n                            </div>\n                        </div>\n                        <div class=\"col-6\">\n                            <div class=\"form-group\">\n                                <label for=\"inputOLEndTime\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.end_time.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":36,"column":60},"end":{"line":36,"column":110}}}))
    + "</label>\n                                <input type=\"time\" class=\"form-control\" id=\"inputOLEndTime\" name=\"end_time\" placeholder=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.end_time.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":37,"column":121},"end":{"line":37,"column":177}}}))
    + "\" required>\n                                <small class=\"invalid-feedback\"></small>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"inputOLMaxOrders\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.max_orders.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":43,"column":54},"end":{"line":43,"column":106}}}))
    + "</label>\n                        <input type=\"number\" class=\"form-control\" id=\"inputOLMaxOrders\" name=\"max_orders\" placeholder=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.max_orders.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":44,"column":119},"end":{"line":44,"column":177}}}))
    + "\" min=\"1\" required>\n                        <small class=\"invalid-feedback\"></small>\n                    </div>\n                    <div class=\"form-group\">\n                        <label>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.mode.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":48,"column":31},"end":{"line":48,"column":77}}}))
    + "</label>\n                        <div class=\"checkbox\">\n                            <label>\n                                <input type=\"hidden\" name=\"for_here\" value=\"0\">\n                                <input type=\"checkbox\" name=\"for_here\" value=\"1\" checked=\"checked\">\n                                "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.modes.for_here",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":53,"column":32},"end":{"line":53,"column":64}}}))
    + "\n                            </label>\n                        <div class=\"checkbox\">\n                        </div>\n                            <label>\n                                <input type=\"hidden\" name=\"takeaway\" value=\"0\">\n                                <input type=\"checkbox\" name=\"takeaway\" value=\"1\" checked=\"checked\">\n                                "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.modes.takeaway",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":60,"column":32},"end":{"line":60,"column":64}}}))
    + "\n                            </label>\n                        <div class=\"checkbox\">\n                        </div>\n                            <label>\n                                <input type=\"hidden\" name=\"delivery\" value=\"0\">\n                                <input type=\"checkbox\" name=\"delivery\" value=\"1\" checked=\"checked\">\n                                "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.modes.delivery",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":67,"column":32},"end":{"line":67,"column":64}}}))
    + "\n                            </label>\n                        </div>\n                    </div>\n                    <div class=\"checkbox\">\n                        <label>\n                            <input type=\"hidden\" name=\"online_only\" value=\"0\">\n                            <input type=\"checkbox\" name=\"online_only\" value=\"1\" checked=\"checked\">\n                            "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.create-limit.online_only.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":75,"column":81}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n                <div class=\"modal-footer\">\n                    <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":80,"column":89},"end":{"line":80,"column":113}}}))
    + "</button>\n                    <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.save",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":81,"column":66},"end":{"line":81,"column":88}}}))
    + "</button>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>";
},"useData":true});