var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "selected";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <li class=\"page-item disabled\"><a class=\"page-link\" href=\"#\">&laquo;</a></li>\n			    <li class=\"page-item disabled\"><a class=\"page-link\" href=\"#\">&lsaquo;</a></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               <li class=\"page-item\"><a class=\"page-link page-pagination\" data-page=\"1\" href=\"#\">&laquo;</a></li>\n                <li class=\"page-item\"><a class=\"page-link page-pagination\" data-page=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"previous_page") : depth0), depth0))
    + "\" href=\"#\">&lsaquo;</a></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <li class=\"page-item disabled\"><a class=\"page-link\" href=\"#\">&rsaquo;</a></li>\n                <li class=\"page-item disabled\"><a class=\"page-link\" href=\"#\">&raquo;</a></li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"page-item\"><a class=\"page-link page-pagination\" data-page=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"next_page") : depth0), depth0))
    + "\" href=\"#\">&rsaquo;</a></li>\n                <li class=\"page-item\"><a class=\"page-link page-pagination\" data-page=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"last_page") : depth0), depth0))
    + "\" href=\"#\">&raquo;</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td colspan=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"nb_columns") : depth0), depth0))
    + "\" class=\"text-center\">\n        <div class=\"float-left\">\n            <div class=\"form-inline\">\n                <select class=\"form-control per-page-view\" id=\"per-page-pagination\" name=\"per-page-pagination\">\n                    <option value=10 "
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"per_page") : depth0),10,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":75}}})) != null ? stack1 : "")
    + ">10</option>\n                    <option value=25 "
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"per_page") : depth0),25,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":75}}})) != null ? stack1 : "")
    + ">25</option>\n                    <option value=50 "
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"per_page") : depth0),50,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":75}}})) != null ? stack1 : "")
    + ">50</option>\n                    <option value=100 "
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"per_page") : depth0),100,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":77}}})) != null ? stack1 : "")
    + ">100</option>\n                    <option value=200 "
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"per_page") : depth0),200,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":77}}})) != null ? stack1 : "")
    + ">200</option>\n                </select>\n                <label for=\"per-page-pagination\" class=\"form-label text-muted\"> &nbsp;Results per page</label>\n            </div>\n        </div>\n        <p class=\"text-muted float-right\">\n            Showing results "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"from") : depth0), depth0))
    + " to "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"to") : depth0), depth0))
    + " of "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"total") : depth0), depth0))
    + "\n        </p>\n        <ul class=\"pagination justify-content-center\">\n"
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"current_page") : depth0),1,{"name":"ifeq","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":25,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"last_page") : depth0),(depth0 != null ? lookupProperty(depth0,"current_page") : depth0),{"name":"ifeq","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":33,"column":21}}})) != null ? stack1 : "")
    + "    	</ul>\n    </td>\n</tr>";
},"useData":true});