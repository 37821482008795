import {Controller} from "../../Router";
import {Catalog, CatalogOptionsContent, Option, OptionValue, OptionValueOverride} from "../models";
import CatalogService from "../service";
import i18next from "i18next";
import Bubble from "../../utils/Bubble";
const mainTemplate = require("./main.hbs");

interface InternalOptionValueOverride extends OptionValue {
    price_override: number|null
    disabled: boolean
}
interface InternalOption extends Option{
    disabled: boolean
    values: InternalOptionValueOverride[]
}

export default class CatalogOptionsManager implements Controller {
    container: HTMLElement
    vars: any
    catalog: Catalog
    content: CatalogOptionsContent
    service: CatalogService
    optionValueOverrideByIds: Record<number, OptionValueOverride>

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.catalog = this.vars["catalog"]
        this.service = new CatalogService()
    }

    didLoad(): void {
        this.downloadContent()
    }

    private downloadContent(loadingState = true) {
        if (loadingState) {
            this.container.innerHTML = ''
            this.container.classList.add("loading-state")
        }
        this.service.getCatalogOptionsContent(this.catalog.id)
            .then((content) => {
                this.content = content
                this.optionValueOverrideByIds = {}
                this.content.optionValueOverrides.forEach((current) => this.optionValueOverrideByIds[current.option_value_id] = current)

                this.reloadRootView()
            })
    }

    private reloadRootView() {
        this.container.classList.remove("loading-state")
        this.container.innerHTML = mainTemplate({
            options: this.getOptionStatuses()
        })

        this.container.querySelectorAll<HTMLButtonElement>('.toggle_opt_status').forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault()
                const optionId = parseInt(button.dataset.id)
                const shouldDisable = !!parseInt(button.dataset.status)

                button.textContent = '...'
                button.disabled = true

                if (shouldDisable) {
                    this.service.disableOption(this.catalog.id, optionId)
                        .then(() => {
                            this.content.disabledOptionIds.push(optionId)
                            this.reloadRootView()
                        })
                } else {
                    this.service.enableOption(this.catalog.id, optionId)
                        .then(() => {
                            this.content.disabledOptionIds = this.content.disabledOptionIds.filter((id) => id !== optionId)
                            this.reloadRootView()
                        })
                }
            })
        })

        this.container.querySelectorAll<HTMLButtonElement>('.toggle_opt_value_status').forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault()
                const valueId = parseInt(button.dataset.id)
                const shouldDisable = !!parseInt(button.dataset.status)

                this.setLoadingOptionValue(valueId)

                const override = {...this.getOptionValueOverride(valueId)}
                override.disabled = shouldDisable
                this.updateOverride(override)
            })
        })

        this.container.querySelectorAll<HTMLInputElement>('input[type=number]').forEach((input) => {
            input.addEventListener('change', (e) => {
                const valueId = parseInt(input.dataset.id)
                this.setLoadingOptionValue(valueId)

                const override = {...this.getOptionValueOverride(valueId)}
                if (input.value.trim() === "") {
                    override.price = null
                } else {
                    override.price = Math.round(input.valueAsNumber * 100)
                }

                this.updateOverride(override)
            })
        })
    }

    private updateOverride(override: OptionValueOverride) {
        this.optionValueOverrideByIds[override.option_value_id] = override
        this.reloadRootView()

        this.service.updateOptionValue(this.catalog.id, override)
            .catch(() => Bubble.error(i18next.t("catalog.errors.cannot_save_option_value")))
    }

    private setLoadingOptionValue(optionValueId: number) {
        const tr = this.container.querySelector<HTMLTableRowElement>(`tr[data-option-value-id="${optionValueId}"]`)
        if (tr) {
            tr.style.opacity = "0.2"
            tr.querySelectorAll<HTMLInputElement>('button, input').forEach((el) => el.disabled = true)
        }
    }

    private getOptionStatuses(): InternalOption[] {
        return this.content.options.map((option) => this.convertOptionToInternalOption(option))
    }

    private convertOptionToInternalOption(option: Option): InternalOption {
        return {
            ...option,
            disabled: this.content.disabledOptionIds.indexOf(option.id) !== -1,
            values: option.values.map((value) => this.convertOptionValueToInternalOptionValue(value))
        }
    }

    private convertOptionValueToInternalOptionValue(value: OptionValue):InternalOptionValueOverride  {
        const optionValueOverride = this.getOptionValueOverride(value.id)
        return {
            ...value,
            price_override: optionValueOverride.price,
            disabled: optionValueOverride.disabled
        };
    }

    private getOptionValueOverride(id: number): OptionValueOverride {
        return this.optionValueOverrideByIds[id] || {price: null, disabled: false, option_value_id: id}
    }
}