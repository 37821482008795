import reloadPickers from './colors.js';

jQuery(document).ready(function($){
	var selectTagEvents = function(){
		$('.tags_list input[type=checkbox]').unbind('change');
		$('.tags_list input[type=checkbox]').change(function(){
			if ($(this).prop('checked'))
				$(this).parents('li').last().find('input[type=checkbox]').first().prop('checked', 'checked');
		});
	};
	selectTagEvents();
	var popin_loaded = function(){
		reloadPickers();
		var popin = h_get_popin();
		$(popin.find('input').get(0)).focus();
		popin.find('form').submit(function(){
			h_ajax('add_tag', {
				method:'post',
				dataType:'json',
				data: $(this).serialize(),
				success: function(data){
					if( data.tag_inserted ){
						var tl = $('#tags_list');
						if( tl.length ) {
							var tl_type = tl.attr('data-type')
							if (!tl_type) tl_type = 'dish';
							var id_parent = parseInt(data.tag_parent),
							    id_tag = parseInt(data.tag_inserted);
							var ul = null,
							    li_data = $('<li class="t'+id_tag+'"><label><input type="checkbox" name="'+tl_type+'[tags][]" value="'+id_tag+'" checked="checked" />&nbsp;'+data.tag_name+'</label></li>');
							if (!id_parent)
								ul = tl;
							else {
								var li_parent = tl.find('.t'+id_parent);
								var ul = li_parent.find('ul');
								if (!ul.length) {
									ul = $('<ul></ul>');
									li_parent.append(ul);
								}
							}
							ul.append(li_data);
							selectTagEvents();
							li_data.find('input[type=checkbox]').change()
							tl.parents('.ddbtn').change()
						}
						else
							setTimeout("window.location.reload()", 400);
						setTimeout(h_popin_close, 1000);
					}
					h_popin_open(data.html);
					popin_loaded();
				}
			});
			return false;
		});
	};
	$('#add_tag:not(.disabled)').click(function(){
		h_popin_ajax('add_tag', popin_loaded);
	});
});
