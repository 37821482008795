import CatalogManager from './catalog/CatalogManager'
import OrderLimitsManager from './restaurants/order-limits/OrderLimitsManager'
import CatalogListManager from "./catalog/list/CatalogListManager";
import AdminRestaurantsListManager from "./admin/restaurants/list/AdminRestaurantsListManager";
import ImportMenuManager from "./carte/import/ImportMenuManager";
import AdminWebhooksListManager from "./admin/webhooks/list/AdminWebhooksListManager";
import AdminWebhooksCreateManager from "./admin/webhooks/create/AdminWebhooksCreateManager";
import AdminWebhooksUpdateManager from "./admin/webhooks/update/AdminWebhooksUpdateManager";

export interface Controller {
    didLoad(): void
}

interface ControllerInitiator {
    (rootElement: HTMLElement, vars: Map<string, any>): Controller
}
interface ControllerMap {
    [key: string]: ControllerInitiator;
}

export class Router {
    controllers: ControllerMap = {
        "catalog.list": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new CatalogListManager(rootElement, vars)
        },
        "catalog.manage": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new CatalogManager(rootElement, vars)
        },
        "restaurants.order-limits": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new OrderLimitsManager(rootElement, vars)
        },
        "admin.restaurants.list": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new AdminRestaurantsListManager(rootElement, vars)
        },
        "admin.webhooks.list": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new AdminWebhooksListManager(rootElement, vars)
        },
        "admin.webhooks.add": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new AdminWebhooksCreateManager(rootElement, vars)
        },
        "admin.webhooks.update": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new AdminWebhooksUpdateManager(rootElement, vars)
        },
        "carte.import": (rootElement: HTMLElement, vars: Map<string, any>): Controller => {
            return new ImportMenuManager(rootElement, vars)
        },
    }

    run(rootElement: HTMLElement) {
        let controllerName = rootElement.dataset.action
        let vars = JSON.parse(rootElement.dataset.vars)
        let controller = this.controllers[controllerName](rootElement, vars)
        controller.didLoad()
    }
}