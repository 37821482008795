import {Controller} from "../../../Router";
import {AdminWebhookQueryParams} from "../models";
import AdminWebhookService from "../service";
import Bubble from "../../../utils/Bubble";
import __t from "../../../utils/HandlebarsHelpers/__t";

const mainTemplate = require("./main.hbs")
const adminWebhooksTemplate = require('./webhooks.hbs')
const paginationTemplate = require("../../../templates/pagination.hbs")

export default class AdminWebhooksListManager implements Controller {
    container: HTMLElement
    vars: any
    service: AdminWebhookService
    queryParams: AdminWebhookQueryParams

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.service = new AdminWebhookService()
        this.queryParams = {
            sort: 'created_at',
            sort_direction: 'desc',
            per_page: 10,
        }
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate(this.vars)
        this.loadAdminWebhooks()
    }

    private paginationEventListeners() {
        this.container.querySelectorAll<HTMLElement>('.page-pagination').forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault()
                let linkPage = event.target as HTMLLinkElement
                this.queryParams.page = Number(linkPage.getAttribute('data-page'))
                this.loadAdminWebhooks()
            })
        })
        this.container.querySelectorAll<HTMLButtonElement>('.per-page-view').forEach((button) => {
            button.addEventListener('change', (event) => {
                event.preventDefault()
                let selectPerPage = event.target as HTMLSelectElement
                this.queryParams.per_page = Number(selectPerPage.value)
                this.loadAdminWebhooks()
            })
        })
    }

    loadAdminWebhooks() {
        this.container.classList.add('loading-state')
        this.service.getAdminWebhookList(this.queryParams)
            .then((content) => {
                this.vars['adminWebhooks'] = content.data
                this.vars['pagination'] = content.pagination
                document.getElementById('content-list-admin-webhooks').innerHTML = adminWebhooksTemplate(this.vars)
                document.getElementById('admin_webhooks_tfoot').innerHTML = paginationTemplate(content.pagination)
                this.paginationEventListeners()
                this.container.classList.remove('loading-state')
                const urlSearchParams = new URLSearchParams(window.location.search)
                if(urlSearchParams.get('message') == 'success') {
                    Bubble.success(__t('admin-webhooks.list.messages.success'))
                } else if(urlSearchParams.get('message') == 'success-edit') {
                    Bubble.success(__t('admin-webhooks.list.messages.success_edit'))
                }
            })
            .catch((err: Error) => {
                this.container.classList.remove('loading-state')
                Bubble.error(__t('admin-webhooks.list.messages.no_results_error'))
            })
    }
}
