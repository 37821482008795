var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-md-12\">\n        <h1>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":40}}}))
    + "</h1>\n        <p>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.description",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":45}}}))
    + "</p>\n        <div class=\"row\">\n            <div class=\"col-md-6\">\n                <form method=\"post\" action=\"\" enctype=\"multipart/form-data\" id=\"form-import-products\">\n                    <fieldset>\n                        <legend>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.products.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":69}}}))
    + "</legend>\n                        <div class=\"form-group\">\n                            <p>\n                                "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.products.export-file",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":75}}}))
    + "\n                                <a href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"export_products_route") : depth0), depth0))
    + "\">Export-produits.xlsx <span class=\"fa fa-download\"></span></a>\n                            </p>\n                        </div>\n                        <div class=\"form-group\">\n                            <p>\n                                "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.products.doc",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":32},"end":{"line":18,"column":67}}}))
    + "\n                                <a href=\"https://support.zelty.fr/hc/fr/articles/360020250791-Importer-des-produits\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.products.doc-name-url",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":19,"column":117},"end":{"line":19,"column":161}}}))
    + "</a>\n                            </p>\n                        </div>\n                        <div class=\"form-group\">\n                            <label for=\"importProductsFile\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.products.file-type",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":23,"column":60},"end":{"line":23,"column":101}}}))
    + "</label>\n                            <input type=\"file\" id=\"importProductsFile\" class=\"form-control\" required>\n                        </div>\n                        <div class=\"form-group\">\n                            <input type=\"checkbox\" id=\"overwrite-dish\">\n                            <label for=\"overwrite-dish\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.products.overwrite-checkbox",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":28,"column":56},"end":{"line":28,"column":106}}}))
    + "</label>\n                        </div>\n                        <div class=\"form-group\">\n                        	<input type=\"submit\" class=\"btn btn-primary\" value=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.submit-button",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":31,"column":77},"end":{"line":31,"column":113}}}))
    + "\">\n                        </div>\n                    </fieldset>\n                </form>\n            </div>\n            <div class=\"col-md-6\">\n                <form method=\"post\" action=\"\" enctype=\"multipart/form-data\" id=\"form-import-options\" enctype=\"multipart/form-data\">\n                    <fieldset>\n                        <legend>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.options.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":39,"column":32},"end":{"line":39,"column":68}}}))
    + "</legend>\n                        <div class=\"form-group\">\n                            <p>\n                                "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.options.export-file",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":42,"column":32},"end":{"line":42,"column":74}}}))
    + "\n                                <a href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"export_options_route") : depth0), depth0))
    + "\">Export-options.xlsx <span class=\"fa fa-download\"></span></a>\n                            </p>\n                        </div>\n                        <div class=\"form-group\">\n                            <label for=\"importOptionsFile\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.options.file-type",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":47,"column":59},"end":{"line":47,"column":99}}}))
    + "</label>\n                            <input type=\"file\" id=\"importOptionsFile\" class=\"form-control\" required>\n                        </div>\n                        <div class=\"form-group\">\n                        	<input type=\"submit\" class=\"btn btn-primary\" value=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.submit-button",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":51,"column":77},"end":{"line":51,"column":113}}}))
    + "\">\n                        </div>\n                    </fieldset>\n                </form>\n            </div>\n            <div class=\"col-md-6\">\n                <form method=\"post\" action=\"\" enctype=\"multipart/form-data\" id=\"form-import-menu\" enctype=\"multipart/form-data\">\n                    <fieldset>\n                        <legend>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":59,"column":32},"end":{"line":59,"column":65}}}))
    + "</legend>\n                        <p>\n                            "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.export-file",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":61,"column":67}}}))
    + "\n                            <a href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"template_import_menu_file_link") : depth0), depth0))
    + "\">template-import-carte.xlsx <span class=\"fa fa-download\"></span></a>\n                        </p>\n                        <div class=\"form-group\">\n                            <label for=\"importMenuFile\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.file-type",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":65,"column":56},"end":{"line":65,"column":93}}}))
    + "</label>\n                            <input type=\"file\" id=\"importMenuFile\" class=\"form-control\" required>\n                        </div>\n                        <div class=\"form-group\">\n                            <input type=\"checkbox\" id=\"dryrun\" checked>\n                            <label for=\"dryrun\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.dryrun",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":70,"column":48},"end":{"line":70,"column":82}}}))
    + "</label>\n                            <label><small style=\"color: red\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.dryrun_warning",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":71,"column":61},"end":{"line":71,"column":103}}}))
    + "</small></label>\n                        </div>\n                        <div class=\"form-group\">\n                        	<input type=\"submit\" class=\"btn btn-primary\" title=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.submit_button_title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":74,"column":77},"end":{"line":74,"column":119}}}))
    + "\" value=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.submit-button",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":74,"column":128},"end":{"line":74,"column":164}}}))
    + "\">\n                        </div>\n                    </fieldset>\n                </form>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});