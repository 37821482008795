export class HttpApiError extends Error {
    response: Response
    constructor(response: Response, msg: string|null = null) {
        super(msg ?? response.statusText);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, HttpApiError.prototype);

        this.response = response
    }
}
export class NetworkApiError extends Error {
    response: Response
    constructor(msg: string|null = null) {
        super(msg);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NetworkApiError.prototype);
    }
}
export class JsonMappingApiError extends Error {
    response: Response
    constructor(msg: string|null = null) {
        super(msg);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, JsonMappingApiError.prototype);
    }
}
export default class Api {
    static call(method: string, input: RequestInfo, init?: RequestInit): Promise<any> {
        init = init || {}
        init['method'] = method
        return fetch(input, init)
            .catch(function(err: Error) {
                throw new NetworkApiError(err.message)
            })
            .then(function (response: Response){
                if (!response.ok) {
                    throw new HttpApiError(response)
                }
                return response.json()
                    .catch(function (err: Error) {
                        throw new JsonMappingApiError(err.message)
                    })
            })
    }

    static get(path: RequestInfo, init?: RequestInit): Promise<any> {
        return Api.call('GET', path, init)
    }

    static json(method: string, path: RequestInfo, input: any): Promise<any> {
        return Api.call(method, path, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify(input)
        })
    }

    static serializeForm(form: HTMLFormElement): any {
        let data: any = {}
        form.querySelectorAll('input, textarea').forEach((elem: HTMLInputElement) => {
            if (!elem.checked && (elem.type === 'checkbox' || elem.type === 'radio')) {
                return
            }
            if (elem.disabled || elem.offsetParent === null) {
                return;
            }
            data[elem.name] = elem.value
        })
        form.querySelectorAll('textarea').forEach((elem: HTMLSelectElement) => {
            if (elem.disabled || elem.offsetParent === null) {
                return;
            }
            data[elem.name] = elem.value
        })
        form.querySelectorAll('select').forEach((elem: HTMLSelectElement) => {
            if (elem.disabled || elem.offsetParent === null) {
                return;
            }
            data[elem.name] = elem.value
        })
        return data
    }
}