import {Controller} from "../../../Router";
import AdminWebhookService from "../service";
import Bubble from "../../../utils/Bubble";
import __t from "../../../utils/HandlebarsHelpers/__t";
import {AdminWebhookFormData} from "../models";

const mainTemplate = require("./main.hbs")
const updateAdminWebhookFormTemplate = require('./webhook_update.hbs')

export default class AdminWebhooksUpdateManager implements Controller {
    container: HTMLElement
    vars: any
    service: AdminWebhookService

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.service = new AdminWebhookService()
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate()
        this.loadUpdateAdminWebhookFormTemplate()
    }

    async loadUpdateAdminWebhookFormTemplate() {
        this.container.classList.add('loading-state')
        await this.service.getAdminWebhook(this.getIdFromPath())
            .then((content) => {
                this.vars['currentWebhook'] = content.data
            })
        this.service.getAdminWebhookEvents()
            .then((content) => {
                this.vars['adminWebhookEvents'] = content.data
                const formTemplate = document.getElementById('content-update-admin-webhook')
                formTemplate.innerHTML = updateAdminWebhookFormTemplate(this.vars)
                this.formListeners(formTemplate)
                this.container.classList.remove('loading-state')
            })
            .catch((err: Error) => {
                this.container.classList.remove('loading-state')
                Bubble.error(__t('admin-webhooks.list.messages.error'))
            })
    }

    private formListeners(formTemplate: HTMLElement): void
    {
        const form = formTemplate.querySelector<HTMLFormElement>('.update-admin-webhook-form')
        form.addEventListener('submit',async (event) => {
            event.preventDefault();
            const formData = new FormData(form)
            const event_name= formData.get('eventName') as string
            const target= formData.get('target') as string
            const comment= formData.get('comment') as string
            const webhook_secret= formData.get('webhookSecret') as string

            await this.service.updateAdminWebhook(this.getIdFromPath(),{event_name, target, comment, webhook_secret})
        })
    }

    private getIdFromPath() {
        const path = window.location.pathname
        const segments = path.split('/')
        return segments[segments.length - 1]
    }
}
