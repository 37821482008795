var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"list-group-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parent_id") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":70}}})) != null ? stack1 : "")
    + "\" data-parent-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"parent_id") : depth0), depth0))
    + "\" data-tag-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n       "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"level") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":7},"end":{"line":3,"column":43}}})) != null ? stack1 : "")
    + "<a class=\"tag\" href=\"javascript:void 0\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"color") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":95},"end":{"line":3,"column":177}}})) != null ? stack1 : "")
    + "\n        <div class=\"dropdown float-right\">\n            <button class=\"btn btn-outline-default btn-xs dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n            </button>\n            <div class=\"dropdown-menu dropdown-menu-right\" aria-labelledby=\"dropdownMenuButton\">\n                <button class=\"dropdown-item edit_tag\">\n                    <span class=\"fa fa-edit\"></span>&nbsp;"
    + alias3(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.edit_dropdown.edit_tag",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":9,"column":58},"end":{"line":9,"column":98}}}))
    + "\n                </button>\n                <button class=\"dropdown-item edit_tags_order\">\n                    <span class=\"fa fa-sort\"></span>&nbsp;"
    + alias3(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.edit_dropdown.edit_tags_order",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":12,"column":58},"end":{"line":12,"column":105}}}))
    + "\n                </button>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"shouldShowCatalogsCategoryTranslations") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"dropdown-divider\"></div>\n                <button class=\"dropdown-item text-danger delete delete_tag\">\n                    <i class=\"fa fa-trash\"></i>&nbsp;"
    + alias3(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.edit_dropdown.delete_tag",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":24,"column":53},"end":{"line":24,"column":95}}}))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n"
    + ((stack1 = container.invokePartial(require("./tags.hbs"),depth0,{"name":"tags","hash":{"level":__default(require("../../utils/HandlebarsHelpers/inc.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"level") : depths[1]),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":29,"column":33},"end":{"line":29,"column":47}}}),"tags":(depth0 != null ? lookupProperty(depth0,"children") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return " has-parent-tag";
},"4":function(container,depth0,helpers,partials,data) {
    return "&raquo;&nbsp;";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <span class=\"fa fa-square\" style=\"color: #"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ";\"></span>";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a href=\"/translate/tags/"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" style=\"text-decoration: none; color: inherit;\">\n                        <button class=\"dropdown-item\">\n                            <span class=\"fa fa-language\"></span>&nbsp;"
    + alias1(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.edit_dropdown.translate_tag",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":70},"end":{"line":18,"column":115}}}))
    + "\n                        </button>\n                    </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":30,"column":10}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});