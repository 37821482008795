import * as Noty from 'noty';
import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

export default class Bubble {
    static error(message: string, timeout: number|false|null = null) {
        new Noty({
            text: message,
            type: 'error',
            timeout: timeout ?? 60000
        }).show()
    }
    static success(message: string, timeout: number|false|null = null) {
        new Noty({
            text: message,
            type: 'success',
            timeout: timeout ?? 30000
        }).show()
    }
}