import {JobRequest} from "../../jobs/models";
import {NetworkApiError} from "../../utils/Api";
import JobsService from "../../jobs/service";
import i18next from "i18next";

let template = require("./import_modal.hbs")

export default class ImportManager {
    jobService = new JobsService()
    modal: JQuery<HTMLElement>
    timer: number|null
    shouldDismiss = false
    nbErrors = 0

    constructor() {
        this.modal = $(template()).modal()
        this.modal.on('hidden.bs.modal', () => {
            this.modal.remove()
            this.clearTimeout()
        })
    }

    private setJob(job: JobRequest) {
        this.nbErrors = 0
        if (job.status === "failed") {
            this.showJobError(job.message)
        } else if (job.status === "finished") {
            let results = job.result.import_reports
            this.modal.find('.modal-body').hide()
            this.modal.find('.import-finished')
                .show()
                .find('.import-message')
                .text(i18next.t('carte.import.menu.modal.success'))
            this.modal.find('.import-results')
                .empty()
                results.forEach((result) => {
                    let importType: string = i18next.t('carte.import.menu.available_sheets.' + result.import_type)
                    let createdRows: string = result.created_rows
                    let updatedRows: string = result.updated_rows
                    let displayResults = i18next.t('carte.import.menu.modal.import_results', { type: importType, created_rows: createdRows, updated_rows: updatedRows })

                    if (document.querySelector('.import-results').innerHTML === null) {
                        this.modal.find('.import-results')
                            .text(displayResults)
                    } else (
                        this.modal.find('.import-results')
                            .append('<br>' + displayResults)
                    )
                })
        } else if (!this.shouldDismiss) {
            this.timer = setTimeout(() => {
                this.updateJob(job)
            }, 2000)
        }
    }

    private showJobError(error: string) {
        this.modal.find('.modal-body').hide()
        this.modal.find('.import-error-info').hide()
        this.modal.find('.import-error').show()
        this.modal.find('.import-error-info').text(error).show()
    }

    private updateJob(job: JobRequest) {
        this.jobService.getJobStatus(job.id)
            .then((job: JobRequest) => {
                this.setJob(job)
            })
            .catch((err: Error) => {
                if (this.shouldDismiss) {
                    return
                }
                if (this.isRecoverable(err) || ++this.nbErrors > 5) {
                    this.timer = setTimeout(() => {
                        this.updateJob(job)
                    }, 15000)
                } else {
                    this.showJobError(err)
                }
            })
    }

    private isRecoverable(err: Error): boolean {
        return err instanceof NetworkApiError
    }

    private clearTimeout() {
        if (this.timer != null) {
            clearTimeout(this.timer)
            this.timer = null
        }
        this.shouldDismiss = true
    }

    private dismissModal() {
        this.clearTimeout()
        this.modal.modal('hide')
    }
}
