window.bh_reconstructTable = function(table, data, store){
	table.empty();
	var $ = jQuery;
	var line = 0;
	while (1) {
		var tr = $('<tr></tr>');
		var stop = true;
		for (var day=1;day<8;++day) {
			if (line==0) {
				data[day].sort(function(a, b){
					return a - b;
				});
			}
			var td = $('<td></td>');
			if (typeof data[day][line] != 'undefined') {
				if (stop) stop = false;
				var t = data[day][line];
				var h = parseInt(t/60), m = t%60;
				td.text((h<10?'0':'')+h+':'+(m<10?'0':'')+m);
				td.append($('<a href="javascript:void(0)" class="rm" style="margin-left:10px"><span class="fa fa-trash"></span></a>').click(function(){
					data[$(this).data('day')].splice($(this).data('line'),1)
					bh_reconstructTable(table, data, store)
				}).data('line', line).data('day', day));
			}
			tr.append(td)
		}
		++line;
		if (stop) break;
		table.append(tr)
	}
	if (store) {
		store.val(JSON.stringify(data));
	}
}
window.bookingHours = function(sel, hoursOut){
	var el = jQuery(sel);
	var table = el.find('.op_table');
	var store = el.find('.op_store');
	var data = {1:[],2:[],3:[],4:[],5:[],6:[],7:[]};
	try {
		data = JSON.parse(store.val());
	} catch (e){}

	el.find('.op_add').click(function(){	
		var day = parseInt(el.find('.op_day').val())
		if (isNaN(day) || day < 0 || day > 7){
			alert('Le jour est incorrect.');
			return;
		}
		var time = el.find('.op_time').val().split(':');
		time = parseInt(time[0])*60+parseInt(time[1]);
		if (isNaN(time) || time < 0 || time > 23*60+60) {
			alert('L\'heure est incorrecte.');
			return;
		}
		data[day].push(time);
		bh_reconstructTable(table, data, store);
	});

	bh_reconstructTable(table, data, store);
}
