var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal\" id=\"cm-create-tag\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":74}}}))
    + "</h5>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <form id=\"cm-create-tag-form\">\n                <div class=\"modal-body\">\n                    <p class=\"alert alert-danger cm_error\" style=\"display: none\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.errors.unknown",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":12,"column":81},"end":{"line":12,"column":124}}}))
    + "</p>\n                    <div class=\"form-group\">\n                        <label for=\"inputCMName\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.name.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":14,"column":49},"end":{"line":14,"column":88}}}))
    + "</label>\n                        <input type=\"text\" class=\"form-control\" id=\"inputCMName\" name=\"name\" placeholder=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.name.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":15,"column":106},"end":{"line":15,"column":151}}}))
    + "\" required autofocus>\n                        <small class=\"invalid-feedback\" style=\"display: none\"></small>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"inputCMDescription\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.description.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":102}}}))
    + "</label>\n                        <textarea type=\"text\" class=\"form-control\" id=\"inputCMDescription\" name=\"description\" placeholder=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.description.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":20,"column":123},"end":{"line":20,"column":175}}}))
    + "\"></textarea>\n                        <small class=\"invalid-feedback\" style=\"display: none\"></small>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"inputCMColor\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.color.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":24,"column":50},"end":{"line":24,"column":90}}}))
    + "</label>\n                        <input type=\"color\" class=\"form-control\" id=\"inputCMColor\" name=\"color\" placeholder=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.color.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":25,"column":109},"end":{"line":25,"column":155}}}))
    + "\" required value=\"#ff00ff\">\n                        <small class=\"invalid-feedback\" style=\"display: none\"></small>\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"inputCMIdParent\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.parent.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":29,"column":53},"end":{"line":29,"column":94}}}))
    + "</label>\n                        <select type=\"color\" class=\"form-control\" id=\"inputCMIdParent\" name=\"parent_id\">\n                            <option value=\"\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.create-tag.parent.none",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":31,"column":45},"end":{"line":31,"column":85}}}))
    + "</option>\n                        </select>\n                        <small class=\"invalid-feedback\" style=\"display: none\"></small>\n                    </div>\n                </div>\n                <div class=\"modal-footer\">\n                    <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":37,"column":89},"end":{"line":37,"column":113}}}))
    + "</button>\n                    <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.save",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":38,"column":66},"end":{"line":38,"column":88}}}))
    + "</button>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>";
},"useData":true});