window.oh_reconstructTable = function(table, data, store){
	table.empty();
	var $ = jQuery;
	var line = 0;
	while (1) {
		var tr = $('<tr></tr>');
		var stop = true;
		for (var day=1;day<8;++day) {
			if (line==0) {
				data[day].sort(function(a,b){return a[0]-b[0];});
			}
			var td = $('<td></td>');
			if (typeof data[day][line] != 'undefined') {
				if (stop) stop = false;
				var t1 = data[day][line][0], t2 = data[day][line][1];
				if (t2>=24*60) t2 -= 24*60;
				var h1 = parseInt(t1/60), m1 = t1%60;
				var h2 = parseInt(t2/60), m2 = t2%60;
				td.text((h1<10?'0':'')+h1+':'+(m1<10?'0':'')+m1+' - '+(h2<10?'0':'')+h2+':'+(m2<10?'0':'')+m2+' ')
				td.append($('<a href="javascript:void(0)" class="rm"><span class="fa fa-trash-alt"></span></a>').click(function(){
					data[$(this).data('day')].splice($(this).data('line'),1)
					oh_reconstructTable(table, data, store)
				}).data('line', line).data('day', day));
			}
			tr.append(td)
		}
		++line;
		if (stop) break;
		table.append(tr)
	}
	if (store)
		store.val(JSON.stringify(data));
}
window.openingHours = function(sel, hoursOut){
	var el = jQuery(sel);
	var table = el.find('.op_table');
	var store = el.find('.op_store');
	var data = {1:[],2:[],3:[],4:[],5:[],6:[],7:[]};
	try {
		data = JSON.parse(store.val());
	} catch (e){}

	el.find('.op_add').click(function(){	
		var day = parseInt(el.find('.op_day').val())
		if (isNaN(day) || day < 0 || day > 7){
			alert('Le jour est incorrect.');
			return;
		}
		var time1 = el.find('.op_time1').val().split(':');
		time1 = parseInt(time1[0])*60+parseInt(time1[1]);
		if (isNaN(time1) || time1 < 0 || time1 > 23*60+60) {
			alert('L\'heure de début est incorrecte.');
			return;
		}
		var time2 = el.find('.op_time2').val().split(':');
		time2 = parseInt(time2[0])*60+parseInt(time2[1]);
		if (isNaN(time2) || time2 < 0 || time2 > 23*60+60) {
			alert('L\'heure de fin est incorrecte.');
			return;
		}
		if (time1<0 || time1 >= 24*60) {
			alert('Heure de début incorrecte.');
			return;
		}
		if (time1>=time2) {
			if (!hoursOut) {
				alert('L\'heure de fin doit être supérieure à l\'heure de début.');
				return;
			} else {
				time2 += 24*60;
				if (time2-time1 >= 24*60) {
					alert('Un horaire doit durer au maximum 23:59.');
					return;
				}
			}
		}
		data[day].push([time1,time2]);
		oh_reconstructTable(table, data, store);
	});

	oh_reconstructTable(table, data, store);
}
