(function($){
	var toggle = '[data-toggle="htooltip"]'
	var closeAll = function(e){
		var target = e && $(e.target).parent()
		$(toggle).each(function(i, t) {
			var p = $(t).parent()
			if (!target || (target.parent().length && !target.closest(p).length)) {
				p.removeClass('open')
			}
		})
	}
	$(document).on('click.htooltip', toggle, function(e) {
		var t = $(this), p = t.parent(),
			isOpened = p.hasClass('open')
		if (t.is('.disabled, :disabled')) return
		closeAll()
		if (!isOpened) {
			p.addClass('open')
		}
	})
	.on('click.htooltip', closeAll)
	.on('keydown.htooltip', toggle, function(e) {
		var t = $(this), p = t.parent(),
			isOpened = p.hasClass('open')
		if (/(27|32)/.test(e.which) && isOpened) {
			p.removeClass('open')
			e.preventDefault()
		} else if (!t.isOpened && /(38|40|32)/.test(e.which)) {
			p.addClass('open')
			e.preventDefault()
		}
	})
}(window.jQuery));
