import "./ItemsSelector.scss"
import CatalogItemsManager from "../catalog/items/CatalogItemsManager";
const template = require("../templates/items-selector.hbs")

interface Tag {
    id: number
    name: string
    color: string
    parent_id: number
    position: number
    children: Tag[]
}

export interface Item {
    id: number
    type: string
    name: string
    price: number
    disabled: boolean
    tag_ids: number[]
    tags: Tag[]
}

export class ItemsSelector {
    showMenus: boolean
    canSelectMultipleItems: boolean
    container: HTMLElement
    modalElement: HTMLElement
    constructor(showMenus: boolean, canSelectMultipleItems = true) {
        this.showMenus = showMenus
        this.canSelectMultipleItems = canSelectMultipleItems
    }

    present(manager: CatalogItemsManager) {
        return new Promise((accept: (items: Item[]) => void, reject) => {
            const modal = $(template({
                canSelectMultipleItems: this.canSelectMultipleItems
            })).modal()

            this.modalElement = modal.get(0)
            this.container = this.modalElement.querySelector("#items-selector-content")
            this.downloadItems()
                .then((body) => {
                    const templateContent = require("../templates/items-selector-content.hbs")
                    this.container.classList.remove("loading-state")

                    let savedItemIds = []

                    manager.content.items.forEach(function(ele) {
                        savedItemIds.push(ele.item_type + ele.item_id)
                    })

                    let apiItems: Item[] = this.getItemsWithTags(body.catalog.items, body.catalog.tags)

                    this.container.innerHTML = templateContent({
                        "items": apiItems,
                        "tags": body.catalog.tags,
                    })
                    this.setupEvents(accept)
                })
                .catch((error) => {
                    this.container.classList.remove("loading-state")
                    this.container.querySelector(".error").classList.remove("hide")
                    reject(error)
                })

            modal.on('hidden.bs.modal', () => {
                modal.remove()
            })
        })
    }

    downloadItems() {
        return fetch(`/catalogs/default/content`)
            .then((response: Response) => {
                return response.json()
            })
    }

    getItemsWithTags(items: Item[], tags: Tag[]): Item[] {
        for (let item of items) {
            item.tags = tags.filter(function (tag: Tag) {
                return item.tag_ids.indexOf(tag.id) !== -1
            })
        }

        return items
    }

    setupEvents(accept: (items: Item[]) => void) {
        const searchField: HTMLInputElement = this.container.querySelector("input[name='search']")
        this.addEvent(searchField, 'keyup', () => {
            this.applyFilters()
        })
        if (searchField !== null) {
            searchField.focus()
        }

        this.addEvent(this.container.querySelectorAll("select"), 'change', () => {
            this.applyFilters()
        })

        const mainCheckbox: HTMLInputElement = this.container.querySelector("#toggle_checkboxes")
        this.addEvent(mainCheckbox, "change", () => {
            this.container.querySelectorAll("tbody input[type='checkbox']").forEach((input: HTMLInputElement) => {
                if (input.offsetParent !== null) { // is visible
                    input.checked = mainCheckbox.checked
                }
            })
        })

        const selector: HTMLSelectElement = this.container.querySelector("select[name='tag_id']")
        this.addEvent(this.container.querySelectorAll("table .tag"), "click", function () {
            selector.value = this.dataset.id
            selector.dispatchEvent(new Event('change'))
        })

        this.addEvent(this.modalElement.querySelectorAll(".save"), "click", () => {
            let items: Item[] = []
            this.container.querySelectorAll("tbody input[type='checkbox']").forEach((input: HTMLInputElement) => {
                if (input.checked) {
                    items.push({
                        "name": input.dataset.name,
                        "id": parseInt(input.dataset.id),
                        "type": input.dataset.type,
                        "price": parseInt(input.dataset.price),
                        "disabled": input.dataset.disabled === "1",
                        "tags": [],
                        "tag_ids": []
                    })
                }
            })
            accept(items)
        })
    }

    addEvent(element: Element|NodeList|null, eventName: string, callback: EventListenerOrEventListenerObject) {
        if (element instanceof NodeList) {
            element.forEach((el: HTMLElement) => {
                this.addEvent(el, eventName, callback)
            })
            return
        }
        if (element !== null) {
            element.addEventListener(eventName, callback)
        }
    }

    applyFilters() {
        const searchField: HTMLInputElement = this.container.querySelector("input[name='search']")
        const tagSelector: HTMLSelectElement = this.container.querySelector("select[name='tag_id']")
        const statusSelector: HTMLSelectElement = this.container.querySelector("select[name='status']")

        const search = searchField.value.toLowerCase().replace(/^\s+|\s+$/g, '')
        const tagId = parseInt(tagSelector.value)
        const activationStatus = parseInt(statusSelector.value)

        const elements = this.container.querySelectorAll('tbody tr')
        let hasNotCheckedBox = false
        elements.forEach((element: HTMLElement) => {
            const isVisible = this.shouldDisplayElement(element, search, tagId, activationStatus)
           element.style.display = isVisible ? "" : "none"
            if (isVisible && !hasNotCheckedBox && !(<HTMLInputElement>element.querySelector("input[type='checkbox']")).checked) {
                hasNotCheckedBox = true
            }
        })
        const mainCheckbox: HTMLInputElement = this.container.querySelector("#toggle_checkboxes")
        mainCheckbox.checked = !hasNotCheckedBox
    }

    shouldDisplayElement(element: HTMLElement, search: string, tagId: number, activationStatus: number): boolean {
        if (search === "" && !tagId && activationStatus == -1) {
            return true
        }

        let match = !tagId || element.dataset.tags.split(',').indexOf(tagId.toString()) !== -1;
            match = match && (activationStatus === -1 || activationStatus == parseInt(element.dataset.status))
            match = match && (search === "" || element.querySelector('label').textContent.toLowerCase().replace(/^\s+|\s+$/g, '').indexOf(search) !== -1);

        return match
    }
}