import {Controller} from "../../Router";
import {Catalog} from "../models";
import CatalogPusherManager from "../push/CatalogPusherManager";
const mainTemplate = require("./main.hbs")

export default class CatalogListManager implements Controller {
    container: HTMLElement
    vars: any
    catalogs: Catalog[]

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.catalogs = this.vars["catalogs"]
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate(this.vars)
        this.container.classList.remove("loading-state")
        this.setupEvents()
    }

    private setupEvents() {
        this.container.querySelectorAll<HTMLButtonElement>('.push_catalog').forEach((button) => {
            button.addEventListener('click', (event) => {
                event.preventDefault()
                const catalogId = button.dataset.id
                this.catalogs.forEach((catalog) => {
                    if (catalog.id === catalogId) {
                        this.pushCatalogManager(catalog)
                        return
                    }
                })
            })
        })
    }

    private pushCatalogManager(catalog: Catalog) {
        const modal = this.container.querySelector('.modal_content')
        $(modal).modal('show')
        const manager = new CatalogPusherManager(modal, {
            catalog: catalog
        })
        manager.didLoad()
    }
}