function rgb2hex(rgb) {
    rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    function hex(x) {
        return ("0" + parseInt(x).toString(16)).slice(-2);
    }
    return hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
}

export default function reloadPickers() {
	$('.color-picker .dropdown-menu a').unbind('click')
	.click(function(){
		var cp = $(this).parents('.color-picker').first()
		var color = $(this).css('background-color')
		var nocolor = $(this).hasClass('nocolor')
		cp.find('a.selected').removeClass('selected')
		cp.find('input[type=hidden]').val(nocolor?'':rgb2hex(color))
		cp.find('.dropdown-toggle').css('background-color', nocolor?'':color)
		$(this).addClass('selected')
	})
}
