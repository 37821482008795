import {Controller} from '../../Router'
let mainTemplate = require("./main.hbs")
let tagsTemplate = require("./tags.hbs")
let itemsTemplate = require("./items.hbs")
let reorderTagsBodyTemplate = require("./reorder-tags-body.hbs")
import Alert from "../../utils/Alert"
import {ItemsSelector, Item as BaseItem} from "../../utils/ItemsSelector"
import i18next from "i18next"
import Bubble from "../../utils/Bubble";
import Sortable from "sortablejs"
import {Catalog, CatalogContent, Item, Tag} from "../models";

export default class CatalogItemsManager implements Controller {
    container: HTMLElement
    vars: any
    catalog: Catalog
    content: CatalogContent
    currentTag: Tag
    createTagModal: JQuery<HTMLElement>
    reorderTagsModal: JQuery<HTMLElement>
    shouldUpdateTag: Tag

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.catalog = this.vars["catalog"]
        this.shouldUpdateTag = null
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate(this.vars)
        this.createTagModal = $('#cm-create-tag')
        this.reorderTagsModal = $('#cm-reorder-tags')
        this.createTagModal.on('shown.bs.modal', () => {
            this.createTagModal.find('input[autofocus]').first().trigger('focus')
            let select = this.createTagModal.find('#inputCMIdParent')
            select.find('option[value!=""]').remove()
            this.content.tags.filter((tag: Tag) => tag.parent_id === null && (this.shouldUpdateTag === null || tag.id !== this.shouldUpdateTag.id)).forEach((tag: Tag) => {
                select.append($('<option></option>').text(tag.name).val(tag.id))
            })
        })
        this.createTagModal.on('hidden.bs.modal', () => {
            this.shouldUpdateTag = null
        })
        this.downloadContent(this.catalog)
            .then(() => {
                this.container.classList.remove("loading-state")
            })
        let form = this.container.querySelector('#cm-create-tag-form') as HTMLFormElement
        form.addEventListener('submit', (e: Event) => {
            e.preventDefault()
            this.createTag(form, this.shouldUpdateTag)
        })
    }

    createTag(form: HTMLFormElement, tag: Tag) {
        const obj: { [key: string]: string; } = {};
        let elements = form.querySelectorAll("input, select, textarea");
        for(let i = 0; i < elements.length; ++i ) {
            let element = elements[i] as HTMLInputElement
            if (element.name) {
                obj[element.name] = element.value
            }
        }

        form.querySelectorAll('.is-invalid').forEach((input: HTMLInputElement) => {
            input.classList.remove('is-invalid')
        })

        let url = tag !== null ? `/catalogs/${this.catalog.id}/content/tags/${tag.id}` : `/catalogs/${this.catalog.id}/content/tags`;
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            method: "POST",
            body: JSON.stringify(obj)
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status == 200) {
                    if (tag === null) {
                        tag = body.tag
                        this.content.tags.push(tag)
                    } else {
                        Object.assign(tag, body.tag)
                    }
                    this.createTagModal.modal('hide')
                    this.reloadTagsView()
                    form.reset()
                    this.loadTag(tag)
                } else {
                    let errorContainer = form.querySelector('.cm_error') as HTMLElement
                    errorContainer.textContent = body.message
                    errorContainer.style.display = 'block'

                    if (body.errors) {
                        for (let key in Object.keys(body.errors)) {
                            let value = body.errors[key]
                            let input = form.querySelector(`[name=${key}]`) as HTMLInputElement
                            input.classList.add('is-invalid')
                            input.nextElementSibling.textContent = value
                        }
                    }
                }
            })
            .catch((err: Error) => {
                let errorContainer = form.querySelector('.cm_error') as HTMLElement
                errorContainer.textContent = i18next.t('general.errors.unspecific')
                errorContainer.style.display = 'block'
            })
    }

    deleteTag(tag: Tag) {
        let alert = new Alert(i18next.t("catalog.delete-tag.confirm"))
        alert.confirm(() => {
            fetch(`/catalogs/${this.catalog.id}/content/tags/${tag.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: "DELETE"
            })
                .then((response: Response) => {
                    return response.json()
                })
                .then((body) => {
                    if (body.status == 200) {
                        this.content.tags = this.content.tags.filter((element: Tag) => {
                            return element.id != tag.id
                        })
                        this.reloadTagsView()
                    } else {
                        const alert = new Alert(body.message, null, true)
                        alert.present()
                    }
                })
                .catch((err: Error) => {
                    Bubble.error(i18next.t("catalog.errors.cannot_delete_tag"))
                })
        })
    }

    downloadContent(catalog: Catalog) {
        return fetch(`/catalogs/${catalog.id}/content`)
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                this.content = body.catalog
                this.reloadRootView()
            })
    }

    reloadRootView() {
        this.reloadTagsView()
        this.reloadItemsView()
    }

    getTagById(id: number): Tag {
        for (let tag of this.content.tags) {
            if (tag.id == id) {
                return tag
            }
        }
        return null;
    }

    getItemById(id: string): Item {
        for (let item of this.content.items) {
            if (item.id === id) {
                return item
            }
        }
        return null;
    }

    reloadTagsView() {
        let tags: Tag[] = this.content.tags.filter((tag: Tag) => tag.parent_id == null)
        for (let tag of tags) {
            tag.children = this.content.tags.filter((subtag: Tag) => subtag.parent_id == tag.id)
        }

        let container = document.getElementById("catalog-content-tags")
        container.innerHTML = tagsTemplate({
            "currentTag": this.currentTag,
            "tags": tags,
            "shouldShowCatalogsCategoryTranslations": this.vars.shouldShowCatalogsCategoryTranslations,
        })

        let self = this
        let tagElements = container.getElementsByClassName("tag")
        for (let i = 0; i < tagElements.length; i++) {
            tagElements[i].addEventListener('click', function (e: Event) {
                e.stopPropagation()
                let tag = self.getTagById(this.parentElement.dataset.tagId)
                self.loadTag(tag)
            })
        }

        container.querySelectorAll('.edit_tag').forEach((element: HTMLElement) => {
            element.addEventListener('click', (e: Event) => {
                e.stopPropagation()
                let tag = this.getTagById(parseInt(element.parentElement.parentElement.parentElement.dataset.tagId))
                this.editTag(tag)
            })
        })

        container.querySelectorAll('.edit_tags_order').forEach((element: HTMLElement) => {
            element.addEventListener('click', () => {
                let tag = this.getTagById(parseInt(element.parentElement.parentElement.parentElement.dataset.tagId))
                this.reorderTag(tag)
            })
        })

        container.querySelectorAll('.delete_tag').forEach((element: HTMLElement) => {
            element.addEventListener('click', (e: Event) => {
                e.stopPropagation()
                let tag = this.getTagById(parseInt(element.parentElement.parentElement.parentElement.dataset.tagId))
                this.deleteTag(tag)
            })
        })
    }

    editTag(tag: Tag) {
        this.shouldUpdateTag = tag
        this.createTagModal.modal('show')
        this.createTagModal.find('input[name=name]').val(tag.name)
        this.createTagModal.find('textarea[name=description]').val(tag.description)
        this.createTagModal.find('select[name=parent_id]').val(tag.parent_id)
        this.createTagModal.find('input[name=color]').val("#" + tag.color)
    }

    reorderTag(tag: Tag) {
        const siblingTags = this.content.tags.filter((value) => value.parent_id === tag.parent_id)
        const body = this.reorderTagsModal.find('.modal-body').html(reorderTagsBodyTemplate({tags: siblingTags}))
        this.reorderTagsModal.modal('show')
        let sortable = Sortable.create(this.reorderTagsModal.find('.tags-sorting').get(0), {
            handle: ".drag_element"
        })
        this.reorderTagsModal.find('.save').off('click')
            .on('click', () => {
                body.addClass('loading-state')
                fetch(`/catalogs/${this.catalog.id}/content/tags/sort`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    method: 'POST',
                    body: JSON.stringify({'order': sortable.toArray()})
                })
                    .then(() => {
                        this.reorderTagsModal.modal('hide')
                        return this.downloadContent(this.catalog)
                    })
                    .then(() => {
                        body.removeClass('loading-state')
                    })
                    .catch((err: Error) => {
                        Bubble.error(err.message)
                    })
            })
    }

    loadTag(tag: Tag) {
        let container = document.getElementById("catalog-content-tags")
        let element = container.querySelector(`[data-tag-id='${tag.id}'] .tag`)

        this.currentTag = tag
        container.querySelectorAll('.active').forEach((el: HTMLElement) => {
            el.classList.remove('active')
        })
        container.querySelectorAll('.sub-tag-visible').forEach((el: HTMLElement) => {
            el.classList.remove('sub-tag-visible')
        })
        element.parentElement.classList.add('active')
        element.parentElement.parentElement.classList.add('active')
        const selectors = [`.list-group-item[data-parent-id='${tag.id}']`];
        if (tag.parent_id !== null) {
            selectors.push(`.list-group-item[data-parent-id='${tag.parent_id}']`)
        }
        container.querySelectorAll(selectors.join(', ')).forEach((el: HTMLElement) => {
            el.classList.add('sub-tag-visible')
        })
        this.reloadItemsView()
    }

    reloadItemsView() {
        let items: Item[] = (!this.currentTag ? [] : this.content.items.filter((item: Item) => item.tag_id == this.currentTag.id))
            .sort((item1, item2) => {
                return item1.position - item2.position
            })

        let container = document.getElementById("catalog-content-items")
        container.innerHTML = itemsTemplate({
            "tag": this.currentTag,
            "items": items
        })

        const addItemsBtn = container.querySelector('.add_items')
        if (addItemsBtn !== null) {
            addItemsBtn.addEventListener('click', () => {
                this.addItems()
            })
        }

        container.querySelectorAll(".delete_item").forEach((el: HTMLElement) => {
            el.addEventListener('click', () => {
                this.deleteItem(this.getItemById(el.dataset.id))
            })
        })

        container.querySelectorAll("input[name='price']").forEach((el: HTMLInputElement) => {
            el.addEventListener('change', () => {
                const item = this.getItemById(el.dataset.id)
                if (el.value.trim() === "") {
                    item.price = null
                } else {
                    item.price = Math.round(el.valueAsNumber * 100)
                }
                this.saveItem(item)
                    .then(() => {
                        this.reloadItemsView()
                    })
            })
        })

        const list = container.querySelector("tbody")
        if (list !== null) {
            Sortable.create(list, {
                handle: ".drag",
                ghostClass: "moving",
                onEnd: () => {
                    let position = 0;
                    container.querySelectorAll("tbody tr").forEach((el: HTMLElement) => {
                        const item = this.getItemById(el.dataset.id)
                        if (item) {
                            item.position = position
                            this.saveItem(item)
                            position += 1
                        }
                    })
                }
            })
        }
    }

    addItems() {
        const itemSelector = new ItemsSelector()
        itemSelector.present(this)
            .then((items: BaseItem[]) => {
                for (const baseItem of items) {
                    let alreadyExists = false
                    for (const existingItem of this.content.items) {
                        if (existingItem.tag_id === this.currentTag.id
                            && existingItem.item_type === baseItem.type
                            && existingItem.item_id === baseItem.id) {
                            alreadyExists = true
                            break;
                        }
                    }
                    if (alreadyExists) {
                        continue
                    }
                    let item: Item = {
                        "id": null,
                        "name": baseItem.name,
                        "item_id": baseItem.id,
                        "tag_id": this.currentTag.id,
                        "item_type": baseItem.type,
                        "price": null,
                        "base_price": baseItem.price,
                        "position": this.content.items.length,
                        "disabled": baseItem.disabled
                    }
                    this.content.items.push(item)
                    this.saveItem(item)
                        .then(() => {
                            this.reloadItemsView()
                        })
                        .catch(() => {
                            Bubble.error(i18next.t("catalog.errors.cannot_save_item"))
                            this.content.items = this.content.items.filter((element: Item) => {
                                return element != item
                            })
                            this.reloadItemsView()
                        })
                }
                this.reloadItemsView()
            })
    }

    saveItem(item: Item) {
        let url = `/catalogs/${this.catalog.id}/content/items`
        if (item.id) {
            url += `/${item.id}`;
        }
        return fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            method: "POST",
            body: JSON.stringify(item)
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status == 200) {
                    item.id = body.item.id
                    return body.item
                } else {
                    throw new Error(body.message)
                }
            })
    }

    deleteItem(item: Item) {
        let alert = new Alert(i18next.t("catalog.delete-item.confirm"))
        alert.confirm(() => {
            fetch(`/catalogs/${this.catalog.id}/content/items/${item.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: "DELETE"
            })
                .then((response: Response) => {
                    return response.json()
                })
                .then((body) => {
                    if (body.status == 200) {
                        this.content.items = this.content.items.filter((element: Item) => {
                            return element.id != item.id
                        })
                        this.reloadItemsView()
                    } else {
                        throw new Error(body.message)
                    }
                })
                .catch((err: Error) => {
                    Bubble.error(i18next.t("catalog.errors.cannot_delete_item"))
                })
        })
    }
}