export default class RestaurantFilter {
	constructor(element)
	{
		this.container = element
		this.textElement = this.container.find('.current_text')
		this.listElement = this.container.find('.restaurant-tree')
		this.searchInput = this.container.find('.s-restaurant-search')
		this.currentRestaurantId = null

		this.container.find('.dropdown-menu').parent().click(function (e) {
			var t = $(e.target);
			if (t.is('.dropdown-menu') || t.closest('.dropdown-menu').length) {
				e.stopPropagation();
			}
		});

		this.searchInputEvents()
	}

	searchInputEvents()
	{
		let t = this
		this.searchInput.closest('.dropdown').on('shown.bs.dropdown', function (e) {
			t.searchInput.focus()
		})
		this.searchInput.on('keyup', function (e) {
			e.preventDefault()
			let term = removeDiacritics($(this).val()).toLowerCase()
			if (term.length > 0) {
				t.listElement.addClass('searching')
			} else {
				t.listElement.removeClass('searching')
				return
			}
			t.listElement.find('.search-match').removeClass('search-match')
			t.listElement.find('.group-item').each(function (i, el) {
				if (removeDiacritics($(el).text()).toLowerCase().indexOf(term) !== -1) {
					let group = $(el).addClass('search-match')
						.next().addClass('search-match')
					group.find('.group-item').addClass('search-match')
					group.find('.group-container').addClass('search-match')
					group.find('.restaurant-item').addClass('search-match')
					group.parents('.group-container').addClass('search-match')
						.prev().addClass('search-match')
				}
			})
			t.listElement.find('.restaurant-item:not(.search-match)').each(function (i, el) {
				if (removeDiacritics($(el).text()).toLowerCase().indexOf(term) !== -1) {
					$(el).addClass('search-match')
						.parents('.group-container').addClass('search-match')
						.prev().addClass('search-match')
				}
			})
		})
	}

	getRedirectUrl(rid)
	{
		let redirectURL = window.location.pathname + window.location.search + window.location.hash;
		return base_url + 'restaurant_selection?redirectURL='+encodeURIComponent(redirectURL) + '&rid=' + rid
	}

	buildTree(rtree, currentRestaurantId)
	{
		let t = this
		this.currentRestaurantId = currentRestaurantId
		this.listElement.empty()
		this.buildAllLink()
		rtree.groups.forEach(function (group) {
			t.buildGroup(t.listElement, group)
		})

		rtree.uncategorizedRestaurants.forEach(function (restaurant) {
			t.buildRestaurant(t.listElement, restaurant)
		})
	}

	buildAllLink()
	{
		let element = $('<a class="dropdown-item" data-rid="all">Tous</a>')
		this.setClickEvent(element, 'all')
		if (!currentRestaurantId) {
			element.addClass('active')
		}
		this.listElement.prepend(element)
	}

	setClickEvent(element, rid)
	{
		let t = this
		element.data('rid', rid)
		element.attr('href', this.getRedirectUrl(rid))
		element.click(function (e) {
			element.attr('href', t.getRedirectUrl(rid))
		})
	}

	buildGroup(container, group)
	{
		let element = $('<a class="dropdown-item group-item"></a>')
		element.attr('href', 'javascript: void 0')
		element.text(group.name)
		container.append(element)

		let groupContainer = $('<div class="group-container"></div>')
		groupContainer.appendTo(container)

		element.click(function () {
			groupContainer.slideToggle(200)
		})

		let t = this
		group.groups.forEach(function (group) {
			t.buildGroup(groupContainer, group)
		})

		group.restaurants.forEach(function (restaurant) {
			t.buildRestaurant(groupContainer, restaurant)
		})
	}

	buildRestaurant(container, restaurant)
	{
		let element = $('<a class="dropdown-item restaurant-item"></a>');
		this.setClickEvent(element, restaurant.id)
		element.text(restaurant.name)
		container.append(element)
		if (this.currentRestaurantId === restaurant.id) {
			element.addClass('active')
			element.parents('.group-container').prev().addClass('active')
		}
	}
}