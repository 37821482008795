
import ImportMenuModule from "./service";
import Bubble from "../../utils/Bubble";
import __t from "../../utils/HandlebarsHelpers/__t";
import {Controller} from "../../Router";
import {JobRequest} from "../../jobs/models";
import ImportManager from "./ImportManager";

const mainTemplate = require("./main.hbs")

export default class ImportMenuManager implements Controller {
    container: HTMLElement
    vars: any
    service: ImportMenuModule

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.service = new ImportMenuModule()
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate(this.vars)
        this.container.classList.remove('loading-state')
        this.setupEvents()
    }

    private setupEvents() {
        this.container.querySelectorAll('form').forEach((form: HTMLFormElement) => {
            form.addEventListener('submit', (e) => {
                e.preventDefault()
                this.container.classList.add('loading-state')
                let form = e.target as HTMLFormElement
                let file = form.querySelector('input[type=file]') as HTMLInputElement
                let type = ''
                let formData = new FormData()
                if (file.files.length > 0) {
                    formData.append('file', file.files[0])
                }
                if (form.getAttribute('id') === 'form-import-options') {
                    type = 'options'
                } else if (form.getAttribute('id') === 'form-import-products') {
                    type = 'dishes'
                    let overwrite = form.querySelector('#overwrite-dish') as HTMLInputElement
                    formData.append('overwrite', String(overwrite.checked))
                } else if (form.getAttribute('id') === 'form-import-menu') {
                    type = 'menu'
                    let dryrun = form.querySelector('#dryrun') as HTMLInputElement
                    formData.append('dryrun', String(dryrun.checked))
                }
                this.importFile(type, formData)
                form.reset()
            })
        })
    }

    private async importFile(type: string, formData: FormData) {
        const importManager = new ImportManager()
        this.service.importFile(type, formData)
            .then((content) => {
                if (type === 'menu') {
                    importManager.setJob(content.data)
                    this.container.classList.remove('loading-state')
                } else {
                    this.container.classList.remove('loading-state')
                    Bubble.success(__t('carte.import.messages.success', content.data))
                }
            })
            .catch((err: Error) => {
                this.container.classList.remove('loading-state')
                importManager.dismissModal()
                if (
                    err.message === 'NetworkError when attempting to fetch resource.'
                    || err.message === 'Failed to fetch'
                ) {
                    Bubble.error(__t('carte.import.messages.errors.failed_to_fetch'))
                } else {
                    Bubble.error(err.message)
                }
            })
    }
}