import CustomersService from "./service";
import Bubble from "../utils/Bubble";
import ExportManager from "../export/ExportManager";
import {JobRequest} from "../jobs/models";

export class CustomersExport {
    exportButton: HTMLButtonElement
    service = new CustomersService()

    constructor() {
        this.exportButton = document.querySelector<HTMLButtonElement>('#customerExport')
        this.setupEvents()
    }

    private setupEvents() {
        if (!this.exportButton) {
            return
        }
        this.exportButton.addEventListener('click', (event) => {
            event.preventDefault()
            const form = this.exportButton.closest('form')
            const exportManager = new ExportManager()
            this.service.exportOrders(new FormData(form))
                .then((job: JobRequest) => {
                    exportManager.setJob(job)
                })
                .catch((err: Error) => {
                    exportManager.dismiss()
                    Bubble.error(err.message)
                })
        })
    }
}