import {Controller} from '../Router'
let mainTemplate = require("./manager.hbs")
import "./catalog.scss"
import {Catalog, CatalogContent} from "./models";
import CatalogItemsManager from "./items/CatalogItemsManager";
import CatalogOptionsManager from "./options/CatalogOptionsManager";
import CatalogMenusManager from "./menus/CatalogMenusManager";
import CatalogPusherManager from "./push/CatalogPusherManager";

export default class CatalogManager implements Controller {
    container: HTMLElement
    contentElement: HTMLElement
    vars: any
    catalog: Catalog
    content: CatalogContent

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.catalog = this.vars["catalog"]
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate(this.vars)
        this.container.classList.remove("loading-state")
        this.contentElement = this.container.querySelector(".catalog_manager_content")
        this.setupEvents()
        this.loadItemsManager()
    }

    private setupEvents() {
        this.container.querySelectorAll<HTMLLinkElement>('.catalog_manager_nav .nav-link').forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault()
                switch (item.dataset.menu) {
                    case "items":
                        this.loadItemsManager()
                        break;
                    case "options":
                        this.loadOptionsManager()
                        break;
                    case "menus":
                        this.loadMenusManager()
                        break;
                }
            })
        })

        this.container.querySelector('.action_push').addEventListener('click', (event) => {
            event.preventDefault()
            this.pushCatalogManager()
        })
    }

    private pushCatalogManager() {
        const modal = this.container.querySelector('.modal_content')
        $(modal).modal('show')
        const manager = new CatalogPusherManager(modal, this.vars)
        manager.didLoad()
    }

    private loadItemsManager() {
        this.activeNav("items")

        this.contentElement.classList.add("loading-state")
        this.contentElement.innerHTML = ''
        const manager = new CatalogItemsManager(this.contentElement, this.vars)
        manager.didLoad()
    }

    private loadOptionsManager() {
        this.activeNav("options")

        this.contentElement.classList.add("loading-state")
        this.contentElement.innerHTML = ''
        const manager = new CatalogOptionsManager(this.contentElement, this.vars)
        manager.didLoad()
    }

    private loadMenusManager() {
        this.activeNav("menus")

        this.contentElement.classList.add("loading-state")
        this.contentElement.innerHTML = ''
        const manager = new CatalogMenusManager(this.contentElement, this.vars)
        manager.didLoad()
    }

    private activeNav(menu: string) {
        this.container.querySelectorAll<HTMLLinkElement>('.catalog_manager_nav .nav-link.active').forEach((item) => {
            item.classList.remove("active")
        })
        const link = this.container.querySelector(`.catalog_manager_nav .nav-link[data-menu=${menu}]`)
        if (link) {
            link.classList.add("active")
        }
    }
}