import {Controller} from "../../Router";
import {
    Catalog, CatalogMenusContent,
    MenuPart, MenuPartDish,
    MenuPartDishOverride,
    MenuPartOverride, OptionValueOverride
} from "../models";
import CatalogService from "../service";
import i18next from "i18next";
import Bubble from "../../utils/Bubble";
const mainTemplate = require("./main.hbs");

interface MenuPartDishWithOverride extends MenuPartDish {
    price_override: number|null
    disabled: boolean
}

interface MenuPartWithOverride extends MenuPart {
    price_override: number|null
    disabled: boolean
    dishes: MenuPartDishWithOverride[]
}

export default class CatalogMenusManager implements Controller {
    container: HTMLElement
    vars: any
    catalog: Catalog
    content: CatalogMenusContent
    service: CatalogService
    menuPartOverrideByIds: Record<number, MenuPartOverride>
    menuPartDishOverrideByIds: Record<number, Record<number, MenuPartDishOverride>>

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.catalog = this.vars["catalog"]
        this.service = new CatalogService()
    }

    didLoad(): void {
        this.downloadContent()
    }

    private downloadContent(loadingState = true) {
        if (loadingState) {
            this.container.innerHTML = ''
            this.container.classList.add("loading-state")
        }
        this.service.getCatalogMenusContent(this.catalog.id)
            .then((content) => {
                this.content = content
                this.menuPartOverrideByIds = {}
                this.content.menuPartOverrides.forEach((current) => this.menuPartOverrideByIds[current.menu_part_id] = current)

                this.menuPartDishOverrideByIds = {}
                this.content.menuParts.forEach((part) => this.menuPartDishOverrideByIds[part.id] = {})
                this.content.menuPartDishOverrides.forEach((current) => {
                    if (current.menu_part_id in this.menuPartDishOverrideByIds) {
                        this.menuPartDishOverrideByIds[current.menu_part_id][current.dish_id] = current
                    }
                })

                this.reloadRootView()
            })
    }


    private reloadRootView() {
        this.container.classList.remove("loading-state")
        this.container.innerHTML = mainTemplate({
            menuParts: this.getMenuPartsWithOverrides()
        })

        this.container.querySelectorAll<HTMLButtonElement>('.toggle_mp_status').forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault()
                const menuPartId = parseInt(button.dataset.menuPartId)
                const override = {...this.getMenuPartOverride(menuPartId)}
                override.disabled = !!parseInt(button.dataset.status)

                this.setLoadingRow(button)

                this.updateMenuPartOverride(override)
            })
        })

        this.container.querySelectorAll<HTMLButtonElement>('.toggle_mp_dish_status').forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault()
                const menuPartId = parseInt(button.dataset.menuPartId)
                const dishId = parseInt(button.dataset.dishId)
                const override = {...this.getMenuPartDishOverride(menuPartId, dishId)}
                override.disabled = !!parseInt(button.dataset.status)

                this.setLoadingRow(button)

                this.updateMenuPartDishOverride(override)
            })
        })

        this.container.querySelectorAll<HTMLInputElement>('input.mp_price').forEach((input) => {
            input.addEventListener('change', (e) => {
                const menuPartId = parseInt(input.dataset.menuPartId)
                const override = {...this.getMenuPartOverride(menuPartId)}
                if (input.value.trim() === "") {
                    override.price = null
                } else {
                    override.price = Math.round(input.valueAsNumber * 100)
                }

                this.setLoadingRow(input)

                this.updateMenuPartOverride(override)
            })
        })

        this.container.querySelectorAll<HTMLInputElement>('input.mp_dish_price').forEach((input) => {
            input.addEventListener('change', (e) => {
                const menuPartId = parseInt(input.dataset.menuPartId)
                const dishId = parseInt(input.dataset.dishId)
                const override = {...this.getMenuPartDishOverride(menuPartId, dishId)}
                if (input.value.trim() === "") {
                    override.price = null
                } else {
                    override.price = Math.round(input.valueAsNumber * 100)
                }

                this.setLoadingRow(input)

                this.updateMenuPartDishOverride(override)
            })
        })
    }

    private setLoadingRow(tr: HTMLElement|null) {
        while (tr !== null && tr.nodeName !== 'tr') {
            tr = tr.parentElement
        }
        if (tr !== null) {
            tr.style.opacity = "0.2"
            tr.querySelectorAll<HTMLInputElement>('button, input').forEach((el) => el.disabled = true)
        }
    }

    private updateMenuPartOverride(override: MenuPartOverride) {
        this.menuPartOverrideByIds[override.menu_part_id] = override
        this.reloadRootView()

        this.service.updateMenuPartOverride(this.catalog.id, override)
            .catch(() => Bubble.error(i18next.t("catalog.errors.cannot_save_menu_part")))
    }

    private updateMenuPartDishOverride(override: MenuPartDishOverride) {
        if (typeof this.menuPartDishOverrideByIds[override.menu_part_id] === 'undefined') {
            this.menuPartDishOverrideByIds[override.menu_part_id] = {}
        }
        this.menuPartDishOverrideByIds[override.menu_part_id][override.dish_id] = override
        this.reloadRootView()

        this.service.updateMenuPartDishOverride(this.catalog.id, override)
            .catch(() => Bubble.error(i18next.t("catalog.errors.cannot_save_menu_part_dish")))
    }

    private getMenuPartsWithOverrides(): MenuPartWithOverride[] {
        return this.content.menuParts.map((menuPart) => this.convertMenuPart(menuPart))
    }

    private convertMenuPart(menuPart: MenuPart): MenuPartWithOverride {
        let override = this.getMenuPartOverride(menuPart.id)
        return {
            ...menuPart,
            disabled: override.disabled,
            price_override: override.price,
            dishes: menuPart.dishes.map((dish) => this.convertMenuPartDish(menuPart, dish))
        }
    }

    private convertMenuPartDish(menuPart: MenuPart, dish: MenuPartDish): MenuPartDishWithOverride {
        let override = this.getMenuPartDishOverride(menuPart.id, dish.id)
        return {
            ...dish,
            disabled: override.disabled,
            price_override: override.price,
        }
    }

    private getMenuPartOverride(menuPartId: number): MenuPartOverride|null {
        return this.menuPartOverrideByIds[menuPartId] || {menu_part_id: menuPartId, disabled: false, price: null}
    }

    private getMenuPartDishOverride(menuPartId: number, dishId: number): MenuPartDishOverride|null {
        return (this.menuPartDishOverrideByIds[menuPartId] || {})[dishId] || {menu_part_id: menuPartId, dish_id: dishId, disabled: false, price: null}
    }
}