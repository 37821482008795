let template = require("../templates/alert.hbs")

export default class Alert {
    title: string|null
    message: string
    isError: boolean
    size: string
    constructor(message: string, title: string|null = null, isError: boolean = false, size: string = 'md') {
        this.title = title
        this.message = message
        this.isError = isError
        this.size = size
    }

    present() {
        const modal = $(template({
            title: this.title,
            message: this.message,
            canCancel: false,
            isError: this.isError,
            size: this.size
        })).modal()
        modal.on('hidden.bs.modal', () => {
            modal.remove()
        })
    }

    confirm(callback: { (): void }) {
        const modal = $(template({
            title: this.title,
            message: this.message,
            canCancel: true,
            isError: this.isError,
            size: this.size
        })).modal()

        modal.find('.confirm_ok').click((e) => {
            e.preventDefault()
            callback()
            modal.modal('hide')
        })

        modal.on('hidden.bs.modal', () => {
            modal.remove()
        })
    }
}