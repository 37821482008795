var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"children") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":15,"column":33}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">&gt;&nbsp;"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":46,"column":115}}})) != null ? stack1 : "")
    + " data-tags=\""
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":127},"end":{"line":46,"column":157}}})) != null ? stack1 : "")
    + "\">\n            <td><input class=\"nomargin\" type=\"checkbox\" name=\"items\" id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_type") : depth0), depth0))
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_id") : depth0), depth0))
    + "\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_type") : depth0), depth0))
    + ":"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_id") : depth0), depth0))
    + "\" data-name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" data-price=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"price") : depth0), depth0))
    + "\" data-type=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_type") : depth0), depth0))
    + "\" data-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_id") : depth0), depth0))
    + "\" data-disabled=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":47,"column":242},"end":{"line":47,"column":275}}})) != null ? stack1 : "")
    + "\" /></td>\n            <td>\n                <label for=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_type") : depth0), depth0))
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"item_id") : depth0), depth0))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</label>\n                "
    + ((stack1 = __default(require("../utils/HandlebarsHelpers/ifeq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"item_type") : depth0),"menu",{"name":"ifeq","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":50,"column":130}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":53,"column":25}}})) != null ? stack1 : "")
    + "            </td>\n            <td>"
    + alias3(__default(require("../utils/HandlebarsHelpers/price.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"price","hash":{},"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":55,"column":31}}}))
    + "</td>\n        </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"table-danger\" style=\"display:none\" data-status=\"1\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "data-status=\"0\"";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + ",";
},"13":function(container,depth0,helpers,partials,data) {
    return "1";
},"15":function(container,depth0,helpers,partials,data) {
    return "0";
},"17":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge badge-info\">"
    + container.escapeExpression(__default(require("../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"items-selector.items.menu_badge",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":50,"column":73},"end":{"line":50,"column":114}}}))
    + "</span>";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a class=\"tag\" href=\"javascript:void 0\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"color") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":76},"end":{"line":52,"column":121}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"color:#"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"mb-2\">\n    <div class=\"form-row\">\n        <div class=\"col-xs-6\">\n            <input class=\"form-control filter_items\" placeholder=\""
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.search",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":66},"end":{"line":4,"column":105}}}))
    + "\" type=\"text\" name=\"search\" value=\"\" />\n        </div>\n        <div class=\"col-xs-auto\">\n            <select class=\"form-control\" name=\"tag_id\">\n                <option value=\"\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.tags.all",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":74}}}))
    + "</option>\n                <option value=\"none\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.tags.none",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":79}}}))
    + "</option>\n                <optgroup label=\""
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.tags.tags_group",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":81}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":16,"column":29}}})) != null ? stack1 : "")
    + "                </optgroup>\n                <optgroup label=\""
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.tags.tags_group_group",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":33},"end":{"line":18,"column":87}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tags_groups") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":21,"column":29}}})) != null ? stack1 : "")
    + "                </optgroup>\n            </select>\n\n        </div>\n        <div class=\"col-xs-auto\">\n            <select class=\"form-control\" name=\"status\">\n                <option value=\"-1\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.status.all",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":28,"column":35},"end":{"line":28,"column":78}}}))
    + "</option>\n                <option value=\"0\" selected=\"selected\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.status.enabled",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":101}}}))
    + "</option>\n                <option value=\"1\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.filters.status.disabled",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":30,"column":34},"end":{"line":30,"column":82}}}))
    + "</option>\n            </select>\n        </div>\n    </div>\n</form>\n\n<table class=\"table table-sm table-hover\">\n    <thead>\n    <tr>\n        <th><input id=\"toggle_checkboxes\" type=\"checkbox\"></th>\n        <th>"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.items.name",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":40,"column":47}}}))
    + "</th>\n        <th>"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.items.price",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":41,"column":48}}}))
    + "</th>\n    </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":57,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"useData":true});