import {Controller} from '../../Router'
import i18next from "i18next";
import Alert from "../../utils/Alert";
let mainTemplate = require("./manager.hbs");
let limitsDayTemplate = require("./limits-day.hbs");
import Bubble from "../../utils/Bubble";

interface OrderLimit {
    id: string
    day_of_week: number
    start_time: string
    end_time: string
    max_orders: number
    for_here: boolean
    takeaway: boolean
    delivery: boolean
    online_only: boolean
}

export default class CatalogManager implements Controller {
    container: HTMLElement
    limitsTable: HTMLTableSectionElement
    restaurantId: number
    limits: OrderLimit[]
    createModal: JQuery<HTMLElement>
    private limitToUpdate: OrderLimit;

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.restaurantId = vars["restaurant_id"]
        this.limitToUpdate = null
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate()
        this.container.classList.remove("loading-state")
        this.limitsTable = this.container.querySelector<HTMLTableSectionElement>("#limits_table")
        this.createModal = $('#ol-create-limit')
        this.installEvents()
        this.downloadLimits()
    }

    private installEvents() {
        this.container.querySelector(".reload_limits").addEventListener('click', () => this.downloadLimits())

        this.createModal.on('shown.bs.modal', () => {
            this.createModal.find('[autofocus]').first().trigger('focus')
            this.createModal.find('.ol_error').hide()
        })

        this.createModal.on('hidden.bs.modal', () => {
            this.limitToUpdate = null
        })

        let form = this.container.querySelector('#ol-create-limit-form') as HTMLFormElement
        form.addEventListener('submit', (e: Event) => {
            e.preventDefault()
            this.createLimitFromForm(form)
                .then(() => {
                    this.createModal.modal('hide')
                    this.reloadLimitUI()
                })
                .catch((error: Error) => {
                    this.createModal.find('.ol_error').show().text(error.message)
                })
        })
    }

    private downloadLimits() {
        this.setLoading(true)
        fetch(`/restaurants/${this.restaurantId}/order-limits/list`)
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                this.limits = body.limits
                this.reloadLimitUI()
            })
            .catch( () => {
                this.loadingError()
            })
    }

    private setLoading(loading: boolean) {
        if (loading) {
            this.limitsTable.classList.add('loading-state')
            this.container.querySelector('.loading-error').classList.add('hide')
        } else {
            this.limitsTable.classList.remove('loading-state')
        }
    }

    private reloadLimitUI() {
        this.setLoading(false)
        this.limitsTable.querySelectorAll<HTMLTableCellElement>('td').forEach((td, number) => {
            td.innerHTML = limitsDayTemplate({"limits": this.getOrderLimitsForDay(number + 1)})
        })
        this.installLimitsEvents()
    }

    private loadingError() {
        this.setLoading(false)
        this.container.querySelector('.loading-error').classList.remove('hide')
    }

    private createLimitFromForm(form: HTMLFormElement): Promise<any> {
        form.querySelectorAll('.is-invalid').forEach((input: HTMLInputElement) => {
            input.classList.remove('is-invalid')
        })

        const formData = new FormData(form)
        let data: {[key: string]: any} = {};
        formData.forEach((value, key) => {data[key] = value});

        let url = this.limitToUpdate ? `/restaurants/${this.restaurantId}/order-limits/${this.limitToUpdate.id}` : `/restaurants/${this.restaurantId}/order-limits`;
        return fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            method: this.limitToUpdate ? "PATCH" : "POST",
            body: JSON.stringify(data)
        }).then((response: Response) => {
            return response.json()
        })
            .then((body) => {
                if (body.status == 200) {
                    if (this.limitToUpdate) {
                        Object.assign(this.limitToUpdate, body.limit)
                        this.limitToUpdate = null
                    } else {
                        this.limits.push(body.limit)
                    }
                    form.reset()
                } else {
                    if (body.errors) {
                        for (let key of Object.keys(body.errors)) {
                            const input = form.querySelector(`[name=${key}]`) as HTMLInputElement
                            const helper = input.nextElementSibling
                            input.classList.add('is-invalid')
                            helper.textContent = body.errors[key]
                        }
                    }
                    throw new Error(body.message)
                }
            })
    }

    private getOrderLimitsForDay(day: number): OrderLimit[] {
        return this.limits.filter((limit) => limit.day_of_week === day)
            .sort((a, b) => a.start_time.localeCompare(b.start_time))
    }

    private installLimitsEvents() {
        $('#limits_table [data-toggle="tooltip"]').tooltip()
        const deleteButtons = this.limitsTable.querySelectorAll<HTMLButtonElement>('.delete_limit')
        deleteButtons.forEach((button) => {
            button.addEventListener('click', () => {
                const limit = this.getLimitById(button.dataset.id)
                this.deleteLimit(limit)
            })
        })

        const editButtons = this.limitsTable.querySelectorAll<HTMLButtonElement>('.edit_limit')
        editButtons.forEach((button) => {
            button.addEventListener('click', () => {
                const limit = this.getLimitById(button.dataset.id)
                this.editLimit(limit)
            })
        })
    }

    private getLimitById(id: string): OrderLimit | null {
        for (let limit of this.limits) {
            if (limit.id === id) {
                return limit
            }
        }
        return null
    }

    private deleteLimit(limit: OrderLimit) {
        let alert = new Alert(i18next.t("order-limits.delete_confirm"))
        alert.confirm(() => {
            this.limits = this.limits.filter((element: OrderLimit) => {
                return element.id != limit.id
            })
            this.reloadLimitUI()

            fetch(`/restaurants/${this.restaurantId}/order-limits/${limit.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: "DELETE"
            })
                .then((response: Response) => {
                    return response.json()
                })
                .then((body) => {
                    if (body.status !== 200) {
                        throw new Error(body.message)
                    }
                })
                .catch((err: Error) => {
                    this.limits.push(limit)
                    this.reloadLimitUI()
                    Bubble.error(err.message)
                })
        })
    }

    private editLimit(limit: OrderLimit) {
        this.limitToUpdate = limit
        this.createModal.modal('show')
        this.createModal.find('select[name=day_of_week]').val(limit.day_of_week)
        this.createModal.find('input[name=start_time]').val(limit.start_time)
        this.createModal.find('input[name=end_time]').val(limit.end_time)
        this.createModal.find('input[name=max_orders]').val(limit.max_orders)
        this.createModal.find('input[name=for_here]').prop("checked", limit.for_here)
        this.createModal.find('input[name=takeaway]').prop("checked", limit.takeaway)
        this.createModal.find('input[name=delivery]').prop("checked", limit.delivery)
        this.createModal.find('input[name=online_only]').prop("checked", limit.online_only)
    }
}