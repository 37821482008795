var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<p class=\"text-muted\">\n    "
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.list.no_catalog",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":37}}}))
    + "\n</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"list-group mt-2\" style=\"max-width: 500px\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"catalogs") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":48,"column":13}}})) != null ? stack1 : "")
    + "</ul>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"list-group-item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_edit") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"btn-group btn-group-xs float-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_edit") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"btn-group btn-group-xs\">\n                <button class=\"btn btn-outline-secondary dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                </button>\n                <div class=\"dropdown-menu dropdown-menu-right\" aria-labelledby=\"dropdownMenuButton\">\n                    <a class=\"dropdown-item\" href=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"copy_route") : depth0), depth0))
    + "\">\n                        <span class=\"fa fa-copy\"></span>&nbsp;"
    + alias3(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.list.actions.copy",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":33,"column":62},"end":{"line":33,"column":97}}}))
    + "\n                    </a>\n                    <button class=\"dropdown-item push_catalog\" data-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                        <span class=\"fa fa-upload\"></span>&nbsp;"
    + alias3(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.list.actions.push",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":36,"column":64},"end":{"line":36,"column":99}}}))
    + "\n                    </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_edit") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":43,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"manage_route") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " <span class=\"badge badge-warning\">"
    + alias1(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.list.brand_catalog",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":20,"column":55},"end":{"line":20,"column":91}}}))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"btn btn-outline-secondary\" href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"edit_route") : depth0), depth0))
    + "\" title=\""
    + alias1(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.list.actions.edit",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":24,"column":78},"end":{"line":24,"column":113}}}))
    + "\" data-toggle=\"tooltip\">\n                <span class=\"fa fa-edit\"></span>\n            </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"dropdown-divider\"></div>\n                    <a class=\"dropdown-item text-danger\" data-method=\"post\" data-confirm=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.list.actions.delete_confirm",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":40,"column":90},"end":{"line":40,"column":135}}}))
    + "\" href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"delete_route") : depth0), depth0))
    + "\">\n                        <i class=\"fa fa-trash\"></i>&nbsp;"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.list.actions.delete",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":41,"column":57},"end":{"line":41,"column":94}}}))
    + "\n                    </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.list.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":32}}}))
    + "</h1>\n<p>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.list.description",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":37}}}))
    + "</p>\n<hr>\n\n<a class=\"btn btn-info btn-sm mb-2\" href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"add_route") : depth0), depth0))
    + "\">\n    <span class=\"fa fa-plus\"></span>\n    "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.list.add_new",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":34}}}))
    + "\n</a>\n"
    + ((stack1 = __default(require("../../utils/HandlebarsHelpers/ifeq.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"catalogs") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),0,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":50,"column":9}}})) != null ? stack1 : "")
    + "<div class=\"modal modal_content\" id=\"pusherModal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"pusherModal\" aria-hidden=\"true\"></div>";
},"useData":true});