var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <td>\n                <input type=\"checkbox\" id=\"pushers_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"group_id") : depth0), depth0))
    + "\" name=\"pushers["
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "][]\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"group_id") : depth0), depth0))
    + "\" data-pusher-type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" data-pusher-group=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"group_id") : depth0), depth0))
    + "\">\n            </td>\n            <td>\n                <label for=\"pushers_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"group_id") : depth0), depth0))
    + "\">\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n"
    + ((stack1 = __default(require("../../utils/HandlebarsHelpers/ifgt.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"number_of_locations") : depth0),1,{"name":"ifgt","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":14,"column":29}}})) != null ? stack1 : "")
    + "                </label>\n            </td>\n        </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"badge badge-info\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"number_of_locations") : depth0), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"alert alert-secondary\">"
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.pushers.alert",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":64}}}))
    + "</p>\n<table class=\"table-sm\">\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pushers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"useData":true});