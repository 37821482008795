var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1><span class=\"fa fa-clock\"></span> "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":66}}}))
    + "</h1>\n<p>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.description",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":37}}}))
    + "</p>\n<hr>\n\n<div class=\"alert alert-danger loading-error hide\">\n    "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.loading_error",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":40}}}))
    + "\n    <br>\n    <button class=\"btn btn-xs btn-info reload_limits\"><span class=\"fa fa-sync-alt\"></span> "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.reload",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":8,"column":91},"end":{"line":8,"column":120}}}))
    + "</button>\n</div>\n<div class=\"table-responsive-lg\">\n    <table class=\"table loading-state\" id=\"limits_table\">\n        <thead>\n            <tr>\n                <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.monday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":50}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.tuesday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":51}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.wednesday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":53}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.thursday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":52}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.friday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":50}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.saturday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":52}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.dates.sunday",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":50}}}))
    + "</th>\n            </tr>\n        </thead>\n        <tbody id=\"hours_list\">\n            <tr>\n                <td class=\"align-top\">...</td>\n                <td class=\"align-top\">...</td>\n                <td class=\"align-top\">...</td>\n                <td class=\"align-top\">...</td>\n                <td class=\"align-top\">...</td>\n                <td class=\"align-top\">...</td>\n                <td class=\"align-top\">...</td>\n            </tr>\n        </tbody>\n    </table>\n    <div>\n        <button class=\"btn btn-sm btn-info\" data-toggle=\"modal\" data-target=\"#ol-create-limit\"><span class=\"fa fa-plus\"></span> "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"order-limits.add",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":36,"column":128},"end":{"line":36,"column":154}}}))
    + "</button>\n    </div>\n</div>\n"
    + ((stack1 = container.invokePartial(require("./create-limit.hbs"),depth0,{"name":"create-limit.hbs","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});