var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr data-option-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n            <th colspan=\"2\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</th>\n            <td>\n                <button class=\"btn btn-xs btn-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":14,"column":46},"end":{"line":14,"column":90}}})) != null ? stack1 : "")
    + " toggle_opt_status\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-status=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":14,"column":140},"end":{"line":14,"column":173}}})) != null ? stack1 : "")
    + "\">\n                    <span class=\"fa fa-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":15,"column":39},"end":{"line":15,"column":80}}})) != null ? stack1 : "")
    + "\"></span>\n                </button>\n            </td>\n        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":35,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "danger";
},"4":function(container,depth0,helpers,partials,data) {
    return "success";
},"6":function(container,depth0,helpers,partials,data) {
    return "0";
},"8":function(container,depth0,helpers,partials,data) {
    return "1";
},"10":function(container,depth0,helpers,partials,data) {
    return "times";
},"12":function(container,depth0,helpers,partials,data) {
    return "check";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"values") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":34,"column":21}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr data-option-value-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":26,"column":35}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td>\n                        <button class=\"btn btn-xs btn-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":98}}})) != null ? stack1 : "")
    + " toggle_opt_value_status\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-status=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":29,"column":154},"end":{"line":29,"column":187}}})) != null ? stack1 : "")
    + "\">\n                            <span class=\"fa fa-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":30,"column":47},"end":{"line":30,"column":88}}})) != null ? stack1 : "")
    + "\"></span>\n                        </button>\n                    </td>\n                </tr>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <input class=\"form-control\" style=\"max-width: 100px\" type=\"number\" name=\"price\" step=\"0.01\" placeholder=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/price.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"price","hash":{},"data":data,"loc":{"start":{"line":25,"column":133},"end":{"line":25,"column":148}}}))
    + "\""
    + ((stack1 = __default(require("../../utils/HandlebarsHelpers/ifeq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"price_override") : depth0),null,{"name":"ifeq","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":25,"column":149},"end":{"line":25,"column":233}}})) != null ? stack1 : "")
    + " data-id=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/floatPrice.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"price_override") : depth0),{"name":"floatPrice","hash":{},"data":data,"loc":{"start":{"line":25,"column":194},"end":{"line":25,"column":223}}}))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-sm table-hover\">\n    <thead>\n    <tr>\n        <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.options.columns.name",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":50}}}))
    + "</th>\n        <th style=\"max-width: 100px\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.options.columns.price",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":76}}}))
    + "</th>\n        <th>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.options.columns.status",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":52}}}))
    + "</th>\n    </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"options") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"useData":true});