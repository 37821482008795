import {JobRequest, JobStatus} from "./models";
import Api from "../utils/Api";

export default class JobsService {
    constructor() {
    }

    public isWaitingJob(status: JobStatus): boolean {
        return status !== JobStatus.Finished && status !== JobStatus.Failed
    }

    public getJobStatus(jobId: string): Promise<JobRequest> {
        return Api.get(`/jobs/${jobId}`)
            .then((body) => {
                return body.data
            })
    }

    public createJob(url: string): Promise<JobRequest> {
        return Api.get(url)
            .then((body) => {
                return body.data
            })
    }
}