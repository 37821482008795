import {ResponseRestaurant, RestaurantQueryParams} from "./models";
import {JobRequest} from "../../jobs/models";

export default class AdminRestaurantService {
    pathApiUrl: string

    constructor() {
        this.pathApiUrl = '/admin/restaurants'
    }

    public getListRestaurant(searchParams: RestaurantQueryParams): Promise<ResponseRestaurant>
    {
        return fetch( this.pathApiUrl + this.searchUrl(searchParams), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            method: "GET"
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
                let restaurantsList : ResponseRestaurant = {
                    data: body.data,
                    pagination: {
                        current_page: body.pagination.current_page,
                        from: body.pagination.from,
                        to: body.pagination.to,
                        total: body.pagination.total,
                        per_page: body.pagination.per_page,
                        last_page: body.pagination.last_page,
                        previous_page: body.pagination.current_page-1,
                        next_page: body.pagination.current_page+1,
                        url: 'restaurants',
                        nb_columns: 9
                    }
                }
                return restaurantsList
            })
    }

    private searchUrl(searchParams: RestaurantQueryParams): string
    {
        let separator: { [key: number]: string } = {
            0: '?',
            1: '&',
            2: '&',
            3: '&',
            4: '&',
            5: '&',
            6: '&',
            7: '&',
            8: '&',
            9: '&',
            10: '&',
            11: '&'
        }
        let number_parameter: number = 0;
        let searchUrl : string = ''
        if(searchParams.page) {
            searchUrl += separator[number_parameter] + 'page=' + searchParams.page
            number_parameter++
        }
        if (searchParams.csm_id && searchParams.csm_id !== 0) {
            searchUrl += separator[number_parameter] + 'filter[csm_id]=' + searchParams.csm_id
            number_parameter++
        }
        if (searchParams.salesman_id && searchParams.salesman_id !== 0) {
            searchUrl += separator[number_parameter] + 'filter[salesman_id]=' + searchParams.salesman_id
            number_parameter++
        }
        if (searchParams.account_manager_id && searchParams.account_manager_id !== 0) {
            searchUrl += separator[number_parameter] + 'filter[account_manager_id]=' + searchParams.account_manager_id
            number_parameter++
        }
        if (searchParams.search) {
            searchUrl += separator[number_parameter] + 'search=' + searchParams.search
            number_parameter++
        }
        if (searchParams.sort) {
            searchUrl += separator[number_parameter] + 'sort=' + searchParams.sort + '&sort_direction=' + searchParams.direction
            number_parameter++
        }
        if(searchParams.per_page) {
            searchUrl += separator[number_parameter] + 'per_page=' + searchParams.per_page
            number_parameter++
        }
        if (searchParams.type) {
            searchUrl += separator[number_parameter] + 'filter[type]=' + searchParams.type
            number_parameter++
        }
        if (searchParams.typology) {
            searchUrl += separator[number_parameter] + 'filter[typology]=' + searchParams.typology
            number_parameter++
        }
        if (searchParams.seasonality) {
            searchUrl += separator[number_parameter] + 'filter[seasonality]=' + searchParams.seasonality
            number_parameter++
        }
        if (searchParams.status) {
            searchParams.status.forEach(status => {
                searchUrl += (separator[number_parameter] ?? '&') + 'filter[status][]=' + status
                number_parameter++
            })
        } else {
            separator[12] = "&"
            searchUrl += separator[number_parameter] + 'filter[status][]=no_result'
            number_parameter++
        }

        return searchUrl
    }

    public exportRestaurants(queryParams: RestaurantQueryParams, format:string = 'xlsx'): Promise<JobRequest> {
        return fetch( this.pathApiUrl + '/export' +  this.searchUrl(queryParams), {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            }).then((response: Response) => {
                return response.json()
            }).then((body) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
                return body.data
            })
    }
}
