var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div class=\"modal-dialog modal-dialog-centered\" role=\"document\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <h5 class=\"modal-title\" id=\"exampleModalCenterTitle\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.pushers.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":65},"end":{"line":4,"column":96}}}))
    + " - "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h5>\n            <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\""
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.close",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":5,"column":81},"end":{"line":5,"column":104}}}))
    + "\">\n                <span aria-hidden=\"true\">&times;</span>\n            </button>\n        </div>\n        <div class=\"modal-body loading-state pushers_list\">\n            "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.pushers.loading",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":45}}}))
    + "\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":13,"column":81},"end":{"line":13,"column":105}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-primary send_button\" disabled>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"catalog.pushers.push",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":14,"column":79},"end":{"line":14,"column":109}}}))
    + "</button>\n        </div>\n    </div>\n</div>";
},"useData":true});