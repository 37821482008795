import {Controller} from "../../Router";
import {Catalog, CatalogPusherLite, CatalogPusherStatus} from "../models";
import CatalogService from "../service";
import Bubble from "../../utils/Bubble";
import JobsService from "../../jobs/service";

const mainTemplate = require("./main.hbs");
const pushersTemplate = require("./pushers.hbs")
const pushersStatusesTemplate = require("./pushers_statuses.hbs")

export default class CatalogPusherManager implements Controller {
    container: HTMLElement
    pusherList: HTMLElement
    vars: any
    catalog: Catalog
    service: CatalogService
    jobsService: JobsService
    sendButton: HTMLButtonElement

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.catalog = this.vars["catalog"]
        this.service = new CatalogService()
        this.jobsService = new JobsService()
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate(this.catalog)
        this.sendButton = this.container.querySelector<HTMLButtonElement>('.send_button')
        this.sendButton.addEventListener('click', () => { this.pushCatalog() })
        this.pusherList = this.container.querySelector('.pushers_list')
        this.downloadPushers()
    }

    private downloadPushers() {
        this.service.getCatalogPushers()
            .then((pushers) => {
                this.setLoadingState(false)
                this.pusherList.innerHTML = pushersTemplate({
                    "pushers": pushers
                })
                this.setupPushersEvents()
            })
            .catch((error) => {
                this.setLoadingState(false)
                Bubble.error(error.message)
            })
    }

    private setupPushersEvents() {
        this.container.querySelectorAll<HTMLInputElement>('input[type=checkbox]')
            .forEach((checkbox) => {
                checkbox.addEventListener('change', () => {
                    this.updateSendButtonState()
                })
            })
    }

    private updateSendButtonState() {
        this.sendButton.disabled = this.container.querySelectorAll<HTMLInputElement>('input[type=checkbox]:checked').length === 0
    }

    private pushCatalog() {
        let pushers: CatalogPusherLite[] = []
        this.container.querySelectorAll<HTMLInputElement>('input[type=checkbox]:checked').forEach((input) => {
            pushers.push({
                group_id: input.value,
                type: input.dataset.pusherType
            })
        })

        if (pushers.length === 0) {
            this.dismiss()
        }

        this.setLoadingState(true)
        this.service.pushCatalog(this.catalog.id, pushers)
            .then((statuses) => {
                this.setLoadingState(false)
                this.setPusherStatuses(statuses)
            })
            .catch((error) => {
                this.setLoadingState(false)
                Bubble.error(error.message)
            })
    }

    private setLoadingState(loading: boolean) {
        if (loading) {
            this.pusherList.classList.add("loading-state")
        } else {
            this.pusherList.classList.remove("loading-state")
        }
    }

    private dismiss() {
        $(this.container).modal('hide')
    }

    private setPusherStatuses(statuses: CatalogPusherStatus[]) {
        this.container.innerHTML = pushersStatusesTemplate({
            "pushers": statuses
        })
        let hasUnfinishedPush = false;
        statuses.forEach((status) => {
            if (this.jobsService.isWaitingJob(status.job_status)) {
                hasUnfinishedPush = true
            }
        })

        if (hasUnfinishedPush) {
            setTimeout(() => this.updateJobStatuses(statuses), 2000)
        }
    }

    private updateJobStatuses(statuses: CatalogPusherStatus[]) {
        const promises: Promise<boolean>[] = [];
        statuses.forEach((status) => {
            if (this.jobsService.isWaitingJob(status.job_status)) {
                const promise = this.jobsService.getJobStatus(status.job_id)
                    .then((job) => {
                        statuses.forEach((status) => {
                            if (status.job_id === job.id) {
                                status.job_status = job.status
                                status.job_message = job.message
                            }
                        })
                        return true
                    })
                promises.push(promise)
            }
        })

        Promise.all(promises).then(() => {
            this.setPusherStatuses(statuses)
        })
    }
}