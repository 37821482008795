var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":15,"column":87},"end":{"line":15,"column":111}}}))
    + "</button>\n                    <button type=\"button\" class=\"btn btn-primary save\" data-dismiss=\"modal\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.ok",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":16,"column":92},"end":{"line":16,"column":112}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"button\" class=\"btn btn-default save\" data-dismiss=\"modal\">"
    + container.escapeExpression(__default(require("../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":92},"end":{"line":18,"column":116}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal items-selector-modal\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog modal-lg\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h3>"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"items-selector.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":50}}}))
    + "</h3>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <div class=\"modal-body loading-state\" id=\"items-selector-content\">\n                <p class=\"alert alert-danger error hide\">"
    + alias2(__default(require("../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.errors.unspecific",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":92}}}))
    + "</p>\n            </div>\n            <div class=\"modal-footer text-center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canSelectMultipleItems") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});