var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <option>"
    + container.escapeExpression(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"admin-webhooks.form.errors.no_events",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":66}}}))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option selected disabled value=\"\">"
    + container.escapeExpression(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.event_name.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":9,"column":47},"end":{"line":9,"column":106}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"adminWebhookEvents") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":12,"column":21}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " - ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + ")</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form id=\"create-admin-webhook-form\" class=\"create-admin-webhook-form\">\n        <div class=\"form-group\">\n            <label for=\"eventNameDropdown\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.event_name.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":96}}}))
    + "<small><span style=\"color: red\"> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.required",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":4,"column":129},"end":{"line":4,"column":167}}}))
    + "</span></small></label>\n            <select class=\"form-control\" id=\"eventNameDropdown\" name=\"eventName\" required autofocus>\n"
    + ((stack1 = __default(require("../../../utils/HandlebarsHelpers/ifeq.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"adminWebhookEvents") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),0,{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"form-group\">\n            <label for=\"inputTarget\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.target.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":86}}}))
    + "<small><span style=\"color: red\"> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.required",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":17,"column":119},"end":{"line":17,"column":157}}}))
    + "</span></small></label>\n            <input type=\"url\" class=\"form-control\" id=\"inputTarget\" name=\"target\" placeholder=\""
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.target.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":18,"column":95},"end":{"line":18,"column":150}}}))
    + "\" required autofocus>\n            <small class=\"text-muted\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.target.rules",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":19,"column":38},"end":{"line":19,"column":87}}}))
    + "</small>\n        </div>\n        <div class=\"form-group\">\n            <label for=\"inputComment\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.comment.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":22,"column":38},"end":{"line":22,"column":88}}}))
    + "<small><em><span> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.optional",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":22,"column":106},"end":{"line":22,"column":144}}}))
    + "</span></em></small></label>\n            <input type=\"text\" class=\"form-control\" id=\"inputComment\" name=\"comment\" placeholder=\""
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.comment.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":23,"column":98},"end":{"line":23,"column":154}}}))
    + "\" autofocus>\n        </div>\n        <div class=\"form-group\">\n            <label for=\"inputWebhookSecret\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.webhook_secret.label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":101}}}))
    + "<small><em><span> "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.optional",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":26,"column":119},"end":{"line":26,"column":157}}}))
    + "</span></em></small></label>\n            <input type=\"text\" class=\"form-control\" id=\"inputWebhookSecret\" name=\"webhookSecret\" placeholder=\""
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.webhook_secret.placeholder",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":27,"column":110},"end":{"line":27,"column":173}}}))
    + "\" minLength=\"32\" maxLength=\"64\">\n            <small class=\"text-muted\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.webhook_secret.rules",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":28,"column":38},"end":{"line":28,"column":95}}}))
    + " - "
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.form.inputs.webhook_secret.additional_infos",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":28,"column":98},"end":{"line":28,"column":166}}}))
    + "</small>\n        </div>\n        <div>\n            <a class=\"btn btn-secondary\" title=\""
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.add.cancel_button_title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":31,"column":48},"end":{"line":31,"column":96}}}))
    + "\" href=\"/admin/admin-webhooks\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.cancel",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":31,"column":127},"end":{"line":31,"column":151}}}))
    + "</a>\n            <button id=\"submit-create-admin-webhook-form\" type=\"submit\" class=\"btn btn-primary\" title=\""
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"admin-webhooks.add.submit_button_title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":32,"column":103},"end":{"line":32,"column":151}}}))
    + "\">"
    + alias2(__default(require("../../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.save",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":32,"column":153},"end":{"line":32,"column":175}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});