var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog modal-md\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h3 class=\"text-center w-100\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.modal.title",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":5,"column":46},"end":{"line":5,"column":85}}}))
    + "</h3>\n            </div>\n            <div class=\"modal-body import-loading text-center\">\n                <p>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.modal.loading",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":8,"column":19},"end":{"line":8,"column":60}}}))
    + "</p>\n                <span class=\"loader\"></span>\n            </div>\n            <div class=\"modal-body import-error\" style=\"display: none\">\n                <h3 class=\"text-center text-warning\">\n                    <span class=\"fa fa-exclamation-triangle\"></span>\n                </h3>\n                <p>"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"carte.import.menu.modal.error",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":15,"column":58}}}))
    + "</p>\n                <p class=\"import-error-info\" style=\"display: none\"></p>\n            </div>\n            <div class=\"modal-body import-finished\" style=\"display: none\">\n                <p class=\"import-message text-center w-100\"></p>\n                <p class=\"import-results text-center w-100\"></p>\n            </div>\n            <div class=\"modal-footer text-center\">\n                <button type=\"button\" class=\"btn btn-primary confirm_ok\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias1,"general.close",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":23,"column":94},"end":{"line":23,"column":117}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});