window.oc_reconstructTable = function(table, data, store){
	table.empty();
	var $ = jQuery;
	var line = 0;
	data.sort(function(a,b){return a[0]-b[0];});
	var c = data.length;
	for (var i=0;i<c;++i) {
		var tr = $('<tr></tr>')
		tr.append($('<td></td>').text((new Date(data[i][0]*1000)).toLocaleString()));
		tr.append($('<td></td>').text((new Date(data[i][1]*1000)).toLocaleString()));
		tr.append($('<td></td>').text(data[i][2]));
		
		tr.append($('<td></td>').append($('<a href="javascript:void(0)" class="float-right rm"><span class="fa fa-trash"></span></a>').click(function(){
			data.splice($(this).data('i'), 1)
			oc_reconstructTable(table, data, store)
		}).data('i', i)));
		table.append(tr)
	}
	if (store) {
		store.val(JSON.stringify(data));
	}
}
window.closures = function(sel, hoursOut){
	var el = jQuery(sel);
	var table = el.find('.oc_table');
	var store = el.find('.oc_store');
	var data = [];
	try {
		data = JSON.parse(store.val());
	} catch (e){}

	el.find('.oc_add').click(function(){	
		var date1 = el.find('.oc_date1').val(),
		time1 = el.find('.oc_time1').val(),
		date2 = el.find('.oc_date2').val(),
		time2 = el.find('.oc_time2').val();

		var unixdt1 = Date.parse(new Date(
			date1.substring(0,4),
			date1.substring(5,7)-1,
			date1.substring(8,10),
			time1.substring(0,2),
			time1.substring(3,5)
		).toString()),
		unixdt2 = Date.parse(new Date(
			date2.substring(0,4),
			date2.substring(5,7)-1,
			date2.substring(8,10),
			time2.substring(0,2),
			time2.substring(3,5)
		).toString())
		if (isNaN(unixdt1)) {
			alert('La date de début est incorrecte');
			return;
		}
		if (isNaN(unixdt2)) {
			alert('La date de fin est incorrecte');
			return;
		}
		if (unixdt1 >= unixdt2) {
			alert('La date de fin doit être supérieure à la date de début.');
			return;
		}
		if (unixdt2 < new Date().getTime()) {
			alert('Cette péroide est déjà passée.');
			return;
		}
		var info = el.find('.oc_info').val();
		if (!info) {
			alert('Vous devez préciser un message de fermeture.');
			return;
		}
		data.push([unixdt1/1000, unixdt2/1000, info]);
		oc_reconstructTable(table, data, store);
	});

	oc_reconstructTable(table, data, store);
}
