var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <p class=\"text-muted\">"
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.items.select_tag",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":60}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":47,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tag") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h2>\n        <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tag") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</p>\n        <p class=\"text-muted\">"
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias3,"catalog.items.no_dishes",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":63}}}))
    + "</p>\n        <button class=\"btn btn-xs btn-primary add_items\"><span class=\"fa fa-plus\"></span> "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias3,"catalog.items.add_items",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":8,"column":90},"end":{"line":8,"column":123}}}))
    + "</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h2>\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tag") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n            <button class=\"btn btn-xs btn-primary add_items\"><span class=\"fa fa-plus\"></span> "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias3,"catalog.items.add_items",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":12,"column":94},"end":{"line":12,"column":127}}}))
    + "</button>\n        </h2>\n        <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"tag") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "</p>\n        <table class=\"table table-sm\">\n            <thead>\n                <tr>\n                    <th style=\"width: 100px\"></th>\n                    <th>Nom</th>\n                    <th style=\"width: 180px\">Prix</th>\n                    <th style=\"width: 100px\"></th>\n                </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":44,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr class=\"item\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":36},"end":{"line":26,"column":82}}})) != null ? stack1 : "")
    + " data-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                        <td><span class=\"fa fa-grip-horizontal drag\"></span></td>\n                        <td>\n                            "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                            "
    + ((stack1 = __default(require("../../utils/HandlebarsHelpers/ifeq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"item_type") : depth0),"menu",{"name":"ifeq","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":28},"end":{"line":30,"column":135}}})) != null ? stack1 : "")
    + "\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":31,"column":129}}})) != null ? stack1 : "")
    + "\n                        </td>\n                        <td>\n                            <input class=\"form-control\" type=\"number\" name=\"price\" step=\"0.01\" placeholder=\""
    + alias3(__default(require("../../utils/HandlebarsHelpers/price.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"base_price") : depth0),{"name":"price","hash":{},"data":data,"loc":{"start":{"line":34,"column":108},"end":{"line":34,"column":128}}}))
    + "\""
    + ((stack1 = __default(require("../../utils/HandlebarsHelpers/ifeq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),null,{"name":"ifeq","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":34,"column":129},"end":{"line":34,"column":195}}})) != null ? stack1 : "")
    + " data-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" />\n                        </td>\n                        <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":41,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " style=\"opacity: 0.5\"";
},"10":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge badge-info\">"
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.items.menu_badge",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":30,"column":85},"end":{"line":30,"column":119}}}))
    + "</span>";
},"12":function(container,depth0,helpers,partials,data) {
    return "<span class=\"badge badge-danger\">"
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"catalog.items.disabled_badge",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":31,"column":77},"end":{"line":31,"column":115}}}))
    + "</span>";
},"14":function(container,depth0,helpers,partials,data) {
    return "";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/floatPrice.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"floatPrice","hash":{},"data":data,"loc":{"start":{"line":34,"column":165},"end":{"line":34,"column":185}}}))
    + "\"";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button class=\"btn btn-xs btn-danger delete_item\" data-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                                <span class=\"fa fa-trash\"></span>\n                            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tag") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":48,"column":11}}})) != null ? stack1 : "");
},"useData":true});