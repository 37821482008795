import {Controller} from "../../../Router";
import {RestaurantQueryParams} from "../models";
import AdminRestaurantService from "../service";
import Bubble from "../../../utils/Bubble";
import __t from "../../../utils/HandlebarsHelpers/__t";
import ExportManager from "../../../export/ExportManager";
import {JobRequest} from "../../../jobs/models";

const mainTemplate = require("./main.hbs")
const restaurantsTemplate = require('./restaurants.hbs')
const paginationTemplate = require("../../../templates/pagination.hbs")

export default class AdminRestaurantsListManager implements Controller {
    container: HTMLElement
    vars: any
    service: AdminRestaurantService
    queryParams: RestaurantQueryParams

    constructor(rootElement: HTMLElement, vars: any) {
        this.container = rootElement
        this.vars = vars
        this.service = new AdminRestaurantService()
        this.queryParams = {
            direction: 'desc',
            sort: 'id',
            status: ['with_subscription']
        }
    }

    didLoad(): void {
        this.container.innerHTML = mainTemplate(this.vars)
        this.setupEvents()
        this.loadRestaurants()
    }

    private setupEvents() {
        this.container.querySelector('#filter-restaurants-csm')
            .addEventListener('submit', (event) => {
                event.preventDefault()
                let salesman_element = document.getElementById('select-salesman') as HTMLSelectElement
                this.queryParams.salesman_id = Number(salesman_element.value)
                let account_manager_element = document.getElementById('select-account-manager') as HTMLSelectElement
                this.queryParams.account_manager_id = Number(account_manager_element.value)
                let csm_element = document.getElementById('select-csm-restaurants-csm') as HTMLSelectElement
                this.queryParams.csm_id = Number(csm_element.value)
                let search_element = document.getElementById('search-restaurants-csm') as HTMLInputElement
                this.queryParams.search = search_element.value.trim()
                let type_element = document.getElementById('select-type-restaurants') as HTMLSelectElement
                this.queryParams.type = type_element.value
                let typology_element = document.getElementById('select-typology-restaurants') as HTMLSelectElement
                this.queryParams.typology = typology_element.value
                let seasonality_element = document.getElementById('select-seasonality-restaurants') as HTMLSelectElement
                this.queryParams.seasonality = seasonality_element.value
                this.queryParams.page = 1

                this.queryParams.status = AdminRestaurantsListManager.getCheckedBoxesValues('restaurant_status[]')

                this.loadRestaurants()
                return false
            })

        this.container.querySelector('#restaurantsExport').addEventListener('click', (event) => {
            event.preventDefault()
            this.exportRestaurants()
        })
    }

    private exportRestaurants() {
        const exportManager = new ExportManager()
        this.service.exportRestaurants(this.queryParams)
            .then((job: JobRequest) => {
                exportManager.setJob(job)
            })
            .catch((err: Error) => {
                exportManager.dismiss()
                Bubble.error(err.message)
            })
    }

    private paginationEvents() {
        this.container.querySelectorAll<HTMLElement>('.page-pagination').forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault()
                let linkPage = event.target as HTMLLinkElement
                this.queryParams.page = Number(linkPage.getAttribute('data-page'))
                this.loadRestaurants()
            })
        })
        this.container.querySelectorAll<HTMLButtonElement>('.per-page-view').forEach((button) => {
            button.addEventListener('change', (event) => {
                event.preventDefault()
                let selectPerPage = event.target as HTMLSelectElement
                this.queryParams.per_page = Number(selectPerPage.value)
                this.loadRestaurants()
            })
        })
    }

    private restaurantEvents() {
        this.container.querySelectorAll<HTMLElement>('.order-by-column').forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault()
                let linkSort = event.target as HTMLLinkElement
                let sort = linkSort.getAttribute('data-sort-column')
                let direction = linkSort.getAttribute('data-sort-direction')
                this.vars['sort'] = sort
                this.vars['direction'] = direction
                this.queryParams.sort = sort
                this.queryParams.direction = direction
                this.loadRestaurants()
            })
        })
        $('[data-toggle="tooltip"]').tooltip()
    }

    loadRestaurants() {
        this.container.classList.add('loading-state')
        this.service.getListRestaurant(this.queryParams)
            .then((content) => {
                this.vars['restaurants'] = content.data
                this.vars['pagination'] = content.pagination
                document.getElementById('content-list-restaurants').innerHTML = restaurantsTemplate(this.vars)
                document.getElementById('admin_restaurants_tfoot').innerHTML = paginationTemplate(content.pagination)
                this.paginationEvents()
                this.restaurantEvents()
                this.container.classList.remove('loading-state')
            })
            .catch((err: Error) => {
                this.container.classList.remove('loading-state')
                Bubble.error(__t('restaurants.admin.list.error'))
            })
    }

    private static getCheckedBoxesValues(checkboxName) {
        let checkboxes = document.querySelectorAll(`input[name="${checkboxName}"]:checked`);
        let checkboxesChecked = [];
        for (let i=0; i<checkboxes.length; i++) {
            checkboxesChecked.push(checkboxes[i].value);
        }
        return checkboxesChecked.length > 0 ? checkboxesChecked : null;
    }

}
