import {JobRequest} from "../jobs/models";

export default class CustomersService {
    exportOrders(filters: FormData): Promise<JobRequest> {
        const queryString = new URLSearchParams(filters as any).toString()
        return fetch('/customers/export?' + queryString, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
                return body.data
            })
    }
}