import {
    CatalogContent,
    CatalogMenusContent,
    CatalogOptionsContent, CatalogPusher, CatalogPusherLite, CatalogPusherStatus, MenuPartDishOverride,
    MenuPartOverride,
    OptionValueOverride, PushCatalogResponse
} from "./models";

export default class CatalogService {
    constructor() {
    }

    public getCatalogContent(catalogId: string): Promise<CatalogContent>
    {
        return fetch(`/catalogs/${catalogId}/content`)
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                return body.catalog
            })
    }

    public getCatalogOptionsContent(catalogId: string): Promise<CatalogOptionsContent>
    {
        return fetch(`/catalogs/${catalogId}/content/options`)
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                return body.catalog
            })
    }

    public getCatalogMenusContent(catalogId: string): Promise<CatalogMenusContent>
    {
        return fetch(`/catalogs/${catalogId}/content/menu-part-overrides`)
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                return body.catalog
            })
    }

    public enableOption(catalogId: string, optionId: number): Promise<boolean>
    {
        return this.toggleOptionStatus(catalogId, optionId, false)
    }

    public disableOption(catalogId: string, optionId: number): Promise<boolean>
    {
        return this.toggleOptionStatus(catalogId, optionId, true)
    }

    private toggleOptionStatus(catalogId: string, optionId: number, disabled: boolean): Promise<boolean> {
        return fetch(`/catalogs/${catalogId}/content/options/${optionId}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify({disable: disabled})
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                return body.status === 200
            })
    }

    public updateOptionValue(catalogId: string, override: OptionValueOverride): Promise<void> {
        return fetch(`/catalogs/${catalogId}/content/option-values`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify(override)
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
            })
    }

    public updateMenuPartOverride(catalogId: string, override: MenuPartOverride): Promise<void> {
        return fetch(`/catalogs/${catalogId}/content/menu-part-overrides`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify(override)
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
            })
    }

    public updateMenuPartDishOverride(catalogId: string, override: MenuPartDishOverride): Promise<void> {
        return fetch(`/catalogs/${catalogId}/content/menu-part-dish-overrides`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify(override)
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
            })
    }

    public getCatalogPushers(): Promise<CatalogPusher[]> {
        return fetch(`/catalogs/pushers`, {
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body) => {
                return body.pushers
            })
    }

    public pushCatalog(catalogId: string, pushers: CatalogPusherLite[]): Promise<CatalogPusherStatus[]> {
        return fetch(`/catalogs/${catalogId}/push`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify({pushers})
        })
            .then((response: Response) => {
                return response.json()
            })
            .then((body: PushCatalogResponse) => {
                if (body.status !== 200) {
                    throw new Error(body.message)
                }
                return body.pushed.map((pusher) => {
                    return {
                        'name': `${pusher.pusher_name} - ${pusher.context_name}`,
                        'job_id': pusher.job.id,
                        'job_status': pusher.job.status,
                        'job_message': pusher.job.message
                    }
                })
            })
    }
}