var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"mb-5\">\n        <div class=\"row\">\n            <div class=\"col-auto\">\n                <h6>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"start_time") : depth0), depth0))
    + " - "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"end_time") : depth0), depth0))
    + "</h6>\n            </div>\n            <div class=\"col-auto\">\n                <div class=\"dropdown\">\n                    <button class=\"btn btn-outline-secondary btn-xs\" type=\"button\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                        <span class=\"fa fa-caret-down\"></span>\n                    </button>\n                    <div class=\"dropdown-menu dropdown-menu-right\" aria-labelledby=\"dropdownMenuButton\">\n                        <button class=\"dropdown-item edit_limit\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                            <span class=\"fa fa-edit\"></span>&nbsp;Éditer\n                        </button>\n                        <button class=\"dropdown-item text-danger delete delete_limit\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                            <i class=\"fa fa-trash\"></i>&nbsp;Supprimer\n                        </button>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max_orders") : depth0), depth0))
    + " "
    + alias2(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(alias3,"order-limits.data.max_orders_label",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":23,"column":30},"end":{"line":23,"column":74}}}))
    + "</div>\n        <div class=\"flags\">\n            <span class=\"fa fa-utensils"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"for_here") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":157}}})) != null ? stack1 : "")
    + "></span>\n            <span class=\"fa fa-walking"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"takeaway") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":26,"column":38},"end":{"line":26,"column":156}}})) != null ? stack1 : "")
    + "></span>\n            <span class=\"fa fa-bicycle"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"delivery") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":27,"column":38},"end":{"line":27,"column":156}}})) != null ? stack1 : "")
    + "></span>\n            <span class=\"fa fa-globe-europe"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"online_only") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":28,"column":43},"end":{"line":28,"column":171}}})) != null ? stack1 : "")
    + "></span>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "\" data-toggle=\"tooltip\" title=\""
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.modes.for_here",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":25,"column":88},"end":{"line":25,"column":120}}}))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    return " text-muted disabled\"";
},"6":function(container,depth0,helpers,partials,data) {
    return "\" data-toggle=\"tooltip\" title=\""
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.modes.takeaway",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":26,"column":87},"end":{"line":26,"column":119}}}))
    + "\"";
},"8":function(container,depth0,helpers,partials,data) {
    return "\" data-toggle=\"tooltip\" title=\""
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.modes.delivery",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":27,"column":87},"end":{"line":27,"column":119}}}))
    + "\"";
},"10":function(container,depth0,helpers,partials,data) {
    return "\" data-toggle=\"tooltip\" title=\""
    + container.escapeExpression(__default(require("../../utils/HandlebarsHelpers/__t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"order-limits.data.online_only",{"name":"__t","hash":{},"data":data,"loc":{"start":{"line":28,"column":95},"end":{"line":28,"column":134}}}))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"limits") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":31,"column":9}}})) != null ? stack1 : "");
},"useData":true});