/** global: ajax_url */

window.h_get_popin = function(){
	return $('#hpopin');
}
window.h_popin_text = function(txt, classes) {
	h_popin_alert(txt, null, classes)
}
window.h_popin_alert = function(txt, completion, classes) {
	let paragraph = $('<p></p>').text(txt).addClass(classes)
	paragraph.css('white-space', 'pre-wrap')
	var content = $('<div class="popin-txt"></div>')
		.append(
			paragraph
		)
		.append(
			$('<a href="javascript:void 0" class="btn btn-success btn-sm"></a>').text('Ok').click(function(){
				h_popin_close()
				if (completion) {
					completion()
				}
			})
		)
	h_popin_open(content, 'sm')
}
window.h_popin_open = function(content, cls){
	var $ = jQuery;
	var popin = $('#hpopin'), pcontent = popin.find('#hpopin_content');
	if( !popin.length ){
		popin = $('<div id="hpopin"></div>').appendTo($('<div id="hpopin_dshadow"></div>').appendTo($('body')));
		popin.append($('<a href="javascript:void(0);"></a>').click(h_popin_close));
		pcontent = $('<div id="hpopin_content"></div>');
		popin.append(pcontent);
	}
	pcontent.empty();
	if (cls) {
		popin.removeClass();
		popin.addClass(cls)
	} else {
		popin.removeClass('loading');
	}
	if( content ){
		pcontent.append(content);
		pcontent.find('.close_hpopin').click(h_popin_close);
	}
	else
		popin.addClass('loading');
	$('#hpopin_dshadow').fadeIn();		
	$('body').addClass('popin')
}

window.h_popin_ajax = function(action, fn, cls, data){
	if (!action.startsWith('/')) {
		action = ajax_url+action;
	}
	return window.h_popinAjax(action, fn, cls, data)
}

window.h_popinAjax = function(action, fn, cls, data){
	h_popin_open(null, cls);
	hAjax(action, {
		data: data,
		method: data?'POST':'GET',
		dataType: 'json',
		success: function(data) {
			window.rdy = []
			if (typeof data.scripts == 'styles') {
				$.each(data.styles, function(i, style) {
					var styleHtml = $('<link rel="stylesheet">').attr('href', style.href).attr('media', style.media);
					$('head').append(styleHtml)
				})
			}
			h_popin_open(data.html, cls);
			if (typeof data.scripts == 'undefined') {
				data.scripts = []
			}
			var nbScriptsLoading = data.scripts.length;
			var continuefn = function () {
				if (nbScriptsLoading !== 0) {
					return;
				}
				for (var i = 0; i < window.rdy.length; ++i) {
					window.rdy[i]($);
				}
			};
			$.each(data.scripts, function (i, script) {
				var scriptHtml = $('<script>')
					.prop('src', script)
					.on("load error", function () {
						scriptHtml.remove();
						nbScriptsLoading--;
						continuefn();
					});
				document.head.appendChild(scriptHtml[0]);
			})
			if (data.scripts.length === 0) {
				continuefn();
			}
			if (fn) {
				fn(data);
			}
		}
	});
}

window.h_popin_close = function(){
	$('#hpopin_dshadow').fadeOut(400, function(){
		h_get_popin().removeClass()
	});
	$('body').removeClass('popin')
}
