export enum JobStatus {
    Waiting = "waiting",
    Running = "running",
    Finished = "finished",
    Failed = "failed"
}

export interface JobRequest {
    id: string
    status: JobStatus
    message: string
    result: {[key: string]: string}
}